import useAccounts from "../../hooks/useAccounts";
import { useState } from "react";
import AccountModal from "./AccountsModal";
import AddAccountModal from "./AddAccountModal";


const Account = () =>{
    const {displayAccounts, isLoading, accounts} = useAccounts();
    const [displayModal, setDisplayModal] = useState(false);
    const [displayAddModal, setDisplayAddModal] = useState(false)

    function showAccountModal() {
        setDisplayModal(true);
    }
    function closeAccountModal(){
        setDisplayModal(false);
    }

    function showAddAccountModal() {
        setDisplayAddModal(true);
    }
    function closeAddAccountModal(){
        setDisplayAddModal(false);
    }

    return(
        <div>
        <p><button className="ms-2 mt-2 btn btn-info bg-transparent text-light" type="button" onClick={showAccountModal}>
        <i className="bi bi-arrow-clockwise me-2"></i>Load Account
        </button></p>

        <p><button className="ms-2 btn btn-info bg-transparent text-light" type="button" onClick={showAddAccountModal}>
        <i className="bi bi-plus-lg me-2"></i>New Account</button></p>

        <AccountModal display={displayModal} close={closeAccountModal} />
        <AddAccountModal display={displayAddModal} close={closeAddAccountModal} /> 
        </div>
    )

}
export default Account;