import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";   

const useFetchData = () => {
    const [data, setData] = useState("");
    const [firstname,setFirstname] = useState('');
    const [admin,setAdmin] = useState(null);
    const [superAdmin, setSuperAdmin] = useState(null);
    const [loading, setLoading] = useState(true);
    
    const navigate = useNavigate();
    useEffect(() => {
        const sessionString = sessionStorage.getItem('session');
        const user = JSON.parse(sessionString);
        fetch("https://account.coin-flex.net/php/login.php", {
            method: 'post',
            mode: "cors"
        })
        .then(response => response.json())
        .then(dbJSON => {
            if((dbJSON.user==='IsNotActive') && (user === null)){
                navigate('/');
            }
            else if((dbJSON.user !== "IsNotActive") && (dbJSON!=='0')){
                for(let client of dbJSON) {
                    setData(client);
                    setFirstname(client.firstname);
                    client.admin==='true' ? setAdmin(true) : setAdmin(false);
                    client.super==='true' ? setSuperAdmin(true) : setSuperAdmin(false);
                    const sessionData = {
                        id : client.id,
                        firstname: client.firstname,
                        lastname: client.lastname,
                        balance: client.balance,
                        super: client.super,
                        admin: client.admin,
                        email: client.email,
                        country: client.country,
                        reg_date: client.reg_date
                    }
                    sessionStorage.setItem("session",JSON.stringify(sessionData));
                    setLoading(false);
                }
            }
            else if(dbJSON.user === "IsNotActive" && user !== null) {
                setData(user);
                setFirstname(user.firstname);
                user.admin==='true' ? setAdmin(true) : setAdmin(false);
                user.super==='true' ? setSuperAdmin(true) : setSuperAdmin(false);
                setLoading(false);
            }
            else if(dbJSON==='0'){
                navigate('/');
            } 
        })
        .catch(error =>{
            console.log(error);
        })
    },[]);
    return {data,firstname,admin,loading,superAdmin};
}
export default useFetchData;