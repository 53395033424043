import { useState} from "react";

export default function useGetReferralID(){
    const [referralID, setReferralID] = useState("");
        
    function getReferral() {
        // Get the current URL
    var url = new URL(window.location.href);
    // Get the search parameters from the URL
    var searchParams = new URLSearchParams(url.search);
    // Retrieve the value of a specific parameter
    var param1Value = searchParams.get('referral');
    setReferralID(param1Value);
    }
    return{ referralID, getReferral}

}
