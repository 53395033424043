import { useState } from "react";
import { useNavigate } from 'react-router-dom';

const useLogin = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();
    
    const login = (url,data) =>{
        setIsLoading(true);
        fetch(url,{
            method: 'post',
            mode: "cors",
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(dbJSON => {
            setIsLoading(false);
            if (dbJSON==='0' || dbJSON===''){
                setLoginError('Invalid Username or Password');
            }
            else{
                for (let client of dbJSON) {
                    const sessionData = {
                        id : client.id,
                        firstname: client.firstname,
                        lastname: client.lastname,
                        balance: client.balance,
                        super: client.super,
                        admin: client.admin,
                        email: client.email,
                        country: client.country,
                        reg_date: client.reg_date
                    }
                    sessionStorage.setItem("session",JSON.stringify(sessionData));
                    navigate('/account');
                }
            }
        })
    }

    return {
        login,
        isLoading,
        loginError,
    }
}
export default useLogin;