import { useState } from 'react';


export default function useHandleInputs(){
    const [values, setValues] = useState({});
    const [checking, setIsChecking] = useState(false);
    const [checkResponse, setCheckResponse] = useState(false);
    const [emailResponse, setEmailResponse] = useState(false);
    const [pwordResponse, setPwordResponse] = useState(false);
    const [checked, setChecked] = useState(false);

    const handleInputs = (e) =>{
        const {name, value} = e.target;
        const inputs = {...values, [name]: value};
        setValues(inputs);
    }


    const resetValues = () => {
      setValues({});
    };
 
    const resetUsername = () => {
        setValues({
          ...values,
          username: '',
        });
    };
    const resetEmail = () => {
        setValues({
          ...values,
          email: '',
        });
    };
    const resetPassword = () => {
        setValues({
          ...values,
          password: '',
        });
    };

    const checkEmail = (email) =>{
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isEmailValid = emailRegex.test(email);
        if(isEmailValid=== false){
            setEmailResponse(`${email} is invalid`);
            resetEmail();
        }
        else{
            setEmailResponse("");
        }
    }

    const checkPassword = (p1,p2) =>{
        // Check if password contains symbols, numbers, and at least one capital letter
        const passwordRegex = /(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z])/;
        const isPasswordValid = passwordRegex.test(p1);
        if (p1 !== p2){
            setPwordResponse("Passwords does not match");
            resetPassword();
            return false;
        }
        else{
            setPwordResponse("");
            if ((p1.length < 8) || (isPasswordValid===false)) {
                setPwordResponse("Passwords must be atleast 8 letters and must contain atleast a one symbol, number and atleast one capitel letter");
                resetPassword();
                return false;
            }
            else{
                setPwordResponse("");
            }
              
        }
    }

    const checkUsername =(uname)=> {
      setIsChecking(true);
      fetch("https://account.coin-flex.net/php/checkUser.php",{
          method: 'post',
          mode: "cors",
          body: JSON.stringify({username:uname})
      })
      .then(response => response.json())
      .then(dbJSON => {
        const usernameRegex = /^[a-zA-Z0-9_-]+$/;
        const isUnameValid = usernameRegex.test(uname);
        if ((uname.length < 6) || (isUnameValid=== false)){
            setCheckResponse("Username must be atleast 6 letters and without any symbol");
            setIsChecking(false);
            resetUsername();
            setChecked(false);
        }
        else {
          if(dbJSON !== "0"){
              setIsChecking(false);
              setCheckResponse(`${uname} already exist`);
              resetUsername();
              setChecked(false);
          }
          else{
              setIsChecking(false);
              setChecked(true);
              setCheckResponse('');
          }
        }
          
      })
  }

    return{
        handleInputs,
        values,
        resetUsername,
        resetValues,
        checkUsername,
        checking,
        checkResponse,
        checked,
        checkEmail,
        checkPassword,
        emailResponse,
        pwordResponse,
        resetEmail,
        resetPassword
    }
}