import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import 'animate.css';
import './css/App.css';
import './css/table.css';
import './css/modal.css';
import './css/switch.css';
import ErrorPage from './404';
import Account from './account';
import History from './history';
import Login from './login';
import Deposit from './deposit';
import Investments from './investments';
import Withdraw from './withdraw';
import Register from './register';
import Inbox from './inbox';

const router = createBrowserRouter([
  
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Account",
    element: <Account />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/History",
    element: <History/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Deposit",
    element: <Deposit/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Investments",
    element: <Investments/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Withdraw",
    element: <Withdraw/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Register",
    element: <Register/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Inbox",
    element: <Inbox/>,
    errorElement: <ErrorPage />,
  },
 
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
);