import useMessage from "../hooks/useMessage";

const Messages = (props) => {
    const {updateSeen} = useMessage();
    const sortedData = props.sorted;
    
    const loadMessages = Array.isArray(sortedData) && sortedData.map(field => {
        function seen(id){
            updateSeen(id);
            let icon = `icon${id}`;
            document.getElementById(icon).innerHTML="";
        }
        
            return (
                <div className="main-timeline" key={field.id}>
                <div className="timeline">
                <a href="##" className="timeline-content">
                <div className="accordion" id="accordionExample">
                <div className="accordion-item border border-0 bg-transparent">
                <div className="accordion-header" id="headingOne">
                 {field.seen === '0' ?
                    <button className="accordion-button collapsed bg-transparent pb-0" type="button" data-bs-toggle="collapse" onClick={()=>seen(field.id)} data-bs-target={"#collapse"+field.id} aria-expanded="true" aria-controls="collapseOne">
                    <div className="position-relative p-1">
                    <span className="subject">{field.subject}</span>
                    <span id={`icon${field.id}`} className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">New</span>
                    </div>
                    </button> 
                    :
                    <button className="accordion-button collapsed bg-transparent pb-0" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+field.id} aria-expanded="true" aria-controls="collapseOne">
                    <div className="position-relative p-1">
                    <span className="subject">{field.subject}</span>
                    <></>
                    </div>
                    </button>
                 } 

                <small className="ms-4 text-secondary pt-0">{field.date}</small>
                </div>
                <div id={"collapse"+field.id} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                <p className="description my-text">
                {field.message}
                </p>
                </div>
                </div>
                </div>
                </div>
                </a>
                </div>
                </div>
            )
    })
   
return(
    <>
    {loadMessages}
    </>
)
}
export default Messages