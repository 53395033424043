import { useState } from 'react';

const useDeleteUser = () =>{
    const [deleteLoading, setIsLoading] = useState(false);
    
    const deleteUser =(id,load)=>{
        setIsLoading(true)
        fetch('https://account.coin-flex.net/php/deleteuser.php', {
            method: 'post',
            mode: "cors",
            body: JSON.stringify({id:id})
        })
        .then(response => response.json())
        .then(data =>{
            if(data==='successful'){
                load();
                setIsLoading(false)
            }
        })
    }
    return{
        deleteUser,
        deleteLoading,
    }
}
export default useDeleteUser;