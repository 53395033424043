import { useState,useEffect } from "react";

export default function useTransactionHistory2(id){

    const [transactionData, setTransactionData] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [lastDeposit, setLastDeposit] = useState("");
    const [lastWithdraw, setlastWithdraw] = useState("");
    const [totalWithdraw, setTotalWithdraw] = useState("");

useEffect(() => {
    if(id){
        setIsLoading(true); 
          const data ={
          userID: id
          };
          fetch("https://account.coin-flex.net/php/displayhistory.php",{
            method: 'post',
            mode: "cors",
            body: JSON.stringify(data)
          })
          .then(response => response.json())
          .then(data => {
            if(data!=='0'){
              setIsLoading(false);
                const sorted = data.sort((a,b) => b.id-a.id);

                const selectDeposits = sorted.filter(field => field.type ==="deposit" && field.phase==="confirmed");
                if(selectDeposits.length > 0){
                  for(let selectDeposit of selectDeposits.slice(0,1)){
                    setLastDeposit(selectDeposit);
                  }
                }
                else{
                  setLastDeposit({amount: "0",date:"0"})
                }    
                const selectWithdraws = sorted.filter(field => field.type ==="withdrawal" && field.phase!=="cancelled");
                // TOTAL WITHDRAWAL
                const withdrawAmount = selectWithdraws.reduce((acc, field) => acc + parseInt(field.amount), 0);
                setTotalWithdraw(withdrawAmount);
                // TOTAL WITHDRAWAL END
                if(selectWithdraws.length > 0){
                  for(let selectWithdraw of selectWithdraws.slice(0,1)){
                    setlastWithdraw(selectWithdraw);
                  }
                }
                else{
                  setlastWithdraw({amount: "0",date:"0"})
                }
                setTransactionData(sorted);
            }
          })
    }
}, [id])


    return{
        transactionData,
        isLoading,
        lastDeposit,
        lastWithdraw,
        totalWithdraw
    }

}