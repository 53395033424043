import useMessage from "../../hooks/useMessage";

const AdminMessages = (props) =>{
    const {isLoading,deleteMessage} = useMessage();
    const messages = props.messages;
    
    const loadMessages = Array.isArray(messages) && messages.map(field => {
        
            return (
                <>
                <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto text-light">
                <small className="fw-bold h6">{field.subject}</small>
                <br></br>
                <small className="mt-1">{field.message}</small>
                <br></br>
                <small className="text-light">{field.date}</small>
                <br></br>
                <button id={field.id} disabled={isLoading} type="button" onClick={() => deleteMessage(field.id,props.load)} className="mt-1 btn btn-sm btn-danger">Delete</button>
                </div>
                <span className="badge fw-normal bg-secondary rounded-pill">{field.seen==="1" ?"Read":"Unread"}</span>
                </li>
                <hr></hr>
                </>
            )
    })
   
return(
    <>
    {loadMessages}
    </>
)
}
export default AdminMessages;