import { useState } from "react";

export default function useLoadAccount(){
    const [accounts, setAccounts] = useState("");

    const loadAccount = () =>{
        fetch("https://account.coin-flex.net/php/loadAccounts.php", {
            method: 'post',
            mode: "cors"
        })
        .then(response => response.json())
        .then(dbJSON => {
            if(dbJSON !== "0"){
                const datafield = dbJSON.map(field => {

                    return(
                        <option key={field.id} name={field.coin} value={field.walletID}>{field.coin}</option>
                    )
                    
                })
                setAccounts(datafield);
            }
        })
    }

    return{loadAccount, accounts}
}