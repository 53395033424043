import useAccounts from "../../hooks/useAccounts";
import useHandleInputs from "../../hooks/useHandleInputs";

const AddAccountModal = (props) =>{
    const {addAccount, deleteLoading} = useAccounts();
    const {handleInputs, values} = useHandleInputs();

    function submit(e){
        e.preventDefault();
        addAccount(values)
    }

  return(
        <div id="myModal" className="modal2 animate__animated animate__fadeIn" style={{ display: props.display? "block":"none" }}>
        <div className="modal-content2 panel rounded-4">

        <div className="panel-footer row">
        <div className=" col-6 btn_group text-start">
        </div>
        <div className=" col-6 btn_group text-end">
        <button type="button" className="btn border-0 btn-default" onClick={props.close}><i className="fa-solid fa-x"></i></button>
        </div>
        
        </div>

        <div className="modal-body  text-light">
        <form className="form-horizontal" onSubmit={submit}>
            <div className="form-group">
            <label>Wallet</label>
            <input type="text" className="form-control form-control-lg mt-2" value={values.coin || ""} name="coin" onChange={handleInputs} required/>
            </div>

            <div className="form-group mt-4">
            <label>Address</label>
            <input type="text" className="form-control form-control-lg mt-2" value={values.walletID || ""} name="walletID" onChange={handleInputs} required/>
            </div>

            <div className="panel-footer row pe-0">
            <div className=" col-6 btn_group text-start">
            </div>
            <div className=" col-6 btn_group text-end">
            <button type="submit" className="btn btn-default"><i className={deleteLoading?"fa fa-spinner fa-spin": "fa fa-add"}></i></button> 
            </div></div>
        </form>

        </div>
        

        </div>
        </div>
    )
}
export default AddAccountModal;