import { useState } from "react";
import useLogin from "./useLogin";


export default function useRegister(){
    const [isLoading, setsIsLoading] = useState(false);
    const [formResponse, setFormResponse] = useState("");
    const {login} = useLogin();

    async function uploadUser(values){
        const mailData = {
          name: values.firstname,
          email: values.email,
          message: `Welcome to Coinflex, Enjoy access to global financial market and the best financial services and support`,
          subject: "Welcome to Coinflex",
        }
      
        setFormResponse("");
        const response = await fetch('https://account.coin-flex.net/php/register.php', {
            method: 'post',
            mode: "cors",
            body: JSON.stringify(values)
        })
        const res = await response.json();
        if(res === 'successful') {
          fetch("https://coin-flex.net/mail.php", {
          method: 'post',
          mode: "cors",
          body: JSON.stringify(mailData)
          })
          .then(response => response.json())
          .then(dbJSON => {
            if(dbJSON.res ==="successful"){
              const logins ={
                uname: values.username,
                password: values.password
              }
              login('https://account.coin-flex.net/php/login.php',logins);
            }
          })
        }
    }

    const register = async (values,resetUsername, resetEmail, resetPassword) => {
        setsIsLoading(true);
        setFormResponse("");
        fetch("https://account.coin-flex.net/php/checkUser.php",{
          method: 'post',
          mode: "cors",
          body: JSON.stringify({username:values.username})
      })
      .then(response => response.json())
      .then(dbJSON => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isEmailValid = emailRegex.test(values.email);
          if(dbJSON === "1" ){
            setsIsLoading(false);
            setFormResponse("Username is unavailable");
            resetUsername();
          }
          else if(isEmailValid === false){
            setsIsLoading(false);
            setFormResponse("Email is Invalid");
            resetEmail()
          }
          else{
            setFormResponse("");
            uploadUser(values);
            
          }
          
      })
    // const logins ={
    //     uname: values.username,
    //     password: values.password
    // }
    // // login('https://account.coin-flex.net/php/login.php',logins);
    // console.log(logins)
    }

    return {
        register,
        isLoading,
        formResponse
    }
}