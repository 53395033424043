import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import useFetchData from "./hooks/useFetchData";
import Loader from "./components/Loader";
import useTransactionHistory2 from "./hooks/useTransactionHistory2";
import useProcessTransactions from "./hooks/useProcessTransactions";

export default function Investments(){
    const {data,loading} = useFetchData();
    const {transactionData} = useTransactionHistory2(data.id);
    const {loading2, withdrawInvest} = useProcessTransactions();
    const spin = <i className="fa fa-spinner fa-spin"></i>;


    const showDeposits = Array.isArray(transactionData) && transactionData.filter(field => field.type ==="deposit");
    const loadInvestments = Array.isArray(showDeposits) && showDeposits.map(field => {

        let increment;
        let days;
        let profit;
        let currentValue;
        let status = "running";
        let showBtn = false;

        let date = new Date(field.confirm_date);
        let today = new Date();
        // let timeDifference = today.getTime() - date.getTime();
        // let daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));


        // Calculate the difference in milliseconds
        var differenceMs = Math.abs(today - date);
        // Convert milliseconds to hours
        var hours = differenceMs / (1000 * 60 * 60);

        if (field.plan ==="initial"){
            increment = 0.06;
            days= 1;
            const newValue = parseFloat(field.amount);
            profit = ((newValue * increment) + newValue) * days;
            if(hours <= 24){
                currentValue = field.amount;
            }
            else if(hours > 24 && field.phase ==="confirmed" && field.status !== "closed"){
                currentValue = profit;
                status = "completed";
                showBtn = true;
            }
            else{
                currentValue = profit;
                status = "completed";
            }
        }
        else if (field.plan ==="mining"){
            increment = 0.15;
            days= 1;
            const newValue = parseFloat(field.amount);
            profit = ((newValue * increment) + newValue) * days;
            if(hours <= 24){
                currentValue = field.amount;
            }
            else if(hours > 24 && field.phase ==="confirmed" && field.status !== "closed"){
                currentValue = profit;
                status = "completed";
                showBtn = true;
            }
            else{
                currentValue = profit;
                status = "completed";
            }
        }
        else{
            increment = 0.3;
            days= 3;
            const newValue = parseFloat(field.amount);
            profit = ((newValue * increment) + newValue) * days;
            if(hours <= 24){
                currentValue = field.amount;
            }
            else if(hours > 24 && hours <= 48){
                currentValue = ((newValue * increment) + newValue) * 2;
            }
            else if((hours > 48 && hours > 72) && field.phase ==="confirmed" && field.status !== "closed"){
                currentValue = profit;
                status = "completed";
                showBtn = true;
            }
            else{
                 currentValue = profit;
                 status = "completed";
            }
            
        }
        const withdrawData ={
            id: data.id,
            userID: field.id,
            balance: parseFloat(data.balance) + profit,
        }
        
            return (
                <>
                <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                <small className="fw-bold h6 text-uppercase">{field.plan} plan</small>
                <br></br>
                <small className="fw-bold">Initial Investment: ${field.amount}</small>
                <br></br>
                <small className="fw-bold">Date Confirmed: {field.confirm_date==="0"? "Not confirmed":field.confirm_date}</small>
                <br></br>
                <small className="mt-1 fw-bold">Expected Income: ${profit}</small><br></br>
                <small className="mt-1 fw-bold">Current value: ${field.phase==="pending"?"":currentValue}</small>
                <br></br>
                <small className={field.phase!=="pending"?"text-success":"text-danger"}>{field.phase}</small>
                <br></br>
                <small className="text-primary">Status: {field.phase==="pending"?"":status}</small>
                <br></br>
                </div>
                <span className=""><button disabled={loading2} className={showBtn?"btn btn-outline-info":"d-none"} onClick={() => withdrawInvest(withdrawData)}>
                {loading2 ? (<>{spin} Withdraw to Wallet</>) : "Withdraw to Wallet"}  
                </button></span>
                </li>
                <hr></hr>
                </>
            )
    })
    
    return(
        <div className="d-flex" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
            <Navbar />
            <main className="min-vh-100 ms-2 me-2 margin-right ms-md-5 me-md-5">
            {loading ? (
            <Loader />
            ) : (
                <div className="animate__animated animate__fadeIn">
                    <div className="col-md-12">
                    <div className="card box2 pe-1 ps-1 p-0" >
                    <div className="card-body row pe-0">
                    <div className="breadcrumb-item h4 bg-transparent mt-1" aria-current="page">Investments</div>
                    </div>
                    </div>
                    </div>

                    <div className="row gy-4 mt-1">
                    <div className="col-md-12">
                    <div className="card box pe-1 ps-1 p-0" >
                    <div className="card-body row pe-0">
                    <div className="col-5 info-box bg-warning-subtle">
                    <i className="bi bi-person-vcard"></i>
                    </div>
                    <div className="col-7 ps-1 p-0">
                    <h4 className="">{data.firstname} {data.lastname}</h4>
                    <span>Registration Date: {data.reg_date}</span>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>

                    <div className="col-12 mt-4">
                    <div className="card box3 " >
                    <div className="card-body">
                    {loadInvestments}
                    </div>
                    </div>
                    </div>
                </div>
            )}
            </main>
            </div>
        </div>
        
    )
}