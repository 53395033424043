import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import useFetchData from "./hooks/useFetchData";
import Loader from "./components/Loader";
import {useNavigate } from "react-router-dom";
import useMessage from "./hooks/useMessage";
import { useEffect } from "react";
import Messages from "./components/Messages";

export default function Inbox(){
    const navigate = useNavigate();
    const {data,loading} = useFetchData();
    const {loadMessage,texts,messageTotal,messageRead,messageUnread,isLoading} = useMessage();

    useEffect(() => {
        loadMessage();
    },[])

    return(
        <div className="d-flex" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
            <Navbar />
            <main className="min-vh-100 ms-2 me-2 margin-right ms-md-5 me-md-5">
            {loading ? (
            <Loader />
            ) : (
                <div className="animate__animated animate__fadeIn">
                    <div className="col-md-12">
                    <div className="card box2 pe-1 ps-1 p-0" >
                    <div className="card-body row pe-0">
                    <div className="breadcrumb-item h4 bg-transparent mt-1" aria-current="page">Inbox</div>
                    </div>
                    </div>
                    </div>

                    <div className="row gy-4 mt-1">
                    <div className="col-md-12">
                    <div className="card box pe-1 ps-1 p-0" >
                    <div className="card-body row pe-0">
                    <div className="col-5 info-box bg-warning-subtle">
                    <i className="bi bi-person-vcard"></i>
                    </div>
                    <div className="col-7 ps-1 p-0">
                    <h4 className="">{data.firstname} {data.lastname}</h4>
                    <span>Registration Date: {data.reg_date}</span>
                    </div>
                    </div>
                    </div>
                    </div>


                     <div className="col-12">
                     <div className="card box3 pe-1 ps-1 p-0" >
                     <div className="card-body row pe-0">
                     <Messages sorted={texts}/>
                     </div>
                     </div>
                     </div>
                    </div>
                </div>
            )}
            </main>
            </div>
        </div>
        
    )
}