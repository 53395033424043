import { useState } from "react";
import { useNavigate } from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom';

export default function useMessage(){
    const [isLoading, setIsLoading] = useState(false);
    const [texts, setTexts] = useState("");
    const [messageTotal, setMessageTotal] = useState("");
    const [messageRead, setMessageRead] = useState("");
    const [messageUnread, setMessageUnread] = useState('');
    const navigate = useNavigate();
    
   // LOAD IMESSAGE USING BROWSER SESSION
    const browserSessionLoad = (userID) => {
        setIsLoading(true);
        let totalMessages = 0;
        let totalRead = 0;
        let totalUnread = 0;
            
        fetch("https://account.coin-flex.net/php/message.php", {
        method: 'post',
        mode: "cors",
        body: JSON.stringify({
            userID: userID
            })
        })
        .then(response => response.json())
        .then(messages =>{
            if(messages!=='0'){
                if (messages.user !== "IsNotActive"){
                    for(let field of messages){
                        totalMessages += parseInt(field.seen.length);
                        if(field.seen==="0"){
                            totalUnread++;
                        }
                        if(field.seen==="1"){
                            totalRead++;
                        }
                    }
                    setMessageTotal(totalMessages);
                    setMessageRead(totalRead);
                    setMessageUnread(totalUnread);
                    // Sort the table data by ID in descending order
                    const sortedData = messages.sort((a, b) => b.id - a.id);
    
                    setTexts(sortedData);
                    setIsLoading(false);
                }
                else if (messages.user === "IsNotActive"){
                    navigate('/');
                }
            }
            else if(messages==='0'){
                setIsLoading(false);
            }
        })
    }
    
    // LOAD IMESSAGE USING PHP SESSION
    const loadMessage = () => {
        setIsLoading(true);
        let totalMessages = 0;
        let totalRead = 0;
        let totalUnread = 0;
        const sessionString = sessionStorage.getItem('session');
        const user = JSON.parse(sessionString);
            
        fetch("https://account.coin-flex.net/php/message.php", {
        method: 'post',
        mode: "cors"
        })
        .then(response => response.json())
        .then(messages =>{
            if(messages!=='0'){
                if (messages.user !== "IsNotActive") {
                    for(let field of messages){
                        totalMessages += parseInt(field.seen.length);
                        if(field.seen==="0"){
                            totalUnread++;
                        }
                        if(field.seen==="1"){
                            totalRead++;
                        }
                    }
                    setMessageTotal(totalMessages);
                    setMessageRead(totalRead);
                    setMessageUnread(totalUnread);
                    // Sort the table data by ID in descending order
                    const sortedData = messages.sort((a, b) => b.id - a.id);
    
                    setTexts(sortedData);
                    setIsLoading(false);
                }
                else if (messages.user === "IsNotActive" && user !== null){
                    browserSessionLoad(user.id)
                }
            }
            else if(messages==='0'){
                setIsLoading(false);
            }
        })
    }

    // ADMIN SECTION MESSAGE
    const loadAdminMessages = (id) => {
        setIsLoading(true);
            
        fetch("https://account.coin-flex.net/php/adminMessages.php", {
        method: 'post',
        mode: "cors",
        body: JSON.stringify({
            userID: id
            })
        })
        .then(response => response.json())
        .then(messages =>{
            if(messages==='0'){
                setIsLoading(false);
                setTexts("");
                return;
            }
            const sortedData = messages.sort((a, b) => b.id - a.id);
            setTexts(sortedData);
            setIsLoading(false);
        })
    }

    // UPDATE UNREAD TO READ
    const updateSeen = (messageID) =>{
        fetch("https://account.coin-flex.net/php/updateSeen.php",{
        method: 'post',
        mode: "cors",
        body: JSON.stringify({id: messageID})
        })
        .then(response => response.json())
        // .then(message =>{
        //     console.log(message)
        // })
    }

    // SEND MESSAGE TO USER
    const sendMessage = (values) =>{
        setIsLoading(true);
        fetch("https://account.coin-flex.net/php/sendMessage.php",{
        method: 'post',
        mode: "cors",
        body: JSON.stringify(values)
        })
        .then(response => response.json())
        .then(message =>{
            if(message==="successful"){
                setIsLoading(false);
            }
        })
    }

    // UPDATE UNREAD TO READ
    const deleteMessage = (messageID,load) =>{
        setIsLoading(true);
        const spin = (
            <><i className="fa fa-spinner fa-spin"></i> Deleting</>
        );
        
        const messageElement = document.getElementById(messageID);
        // Clear existing content
        messageElement.innerHTML = '';
        // Render the JSX using ReactDOM
        ReactDOM.render(spin, messageElement);

        fetch("https://account.coin-flex.net/php/deleteMessage.php",{
        method: 'post',
        mode: "cors",
        body: JSON.stringify({messageID: messageID})
        })
        .then(response => response.json())
        .then(message =>{
            if(message==="successful"){
                setIsLoading(false);
                ReactDOM.render("Delete", messageElement);
                load();
                return;
            }
            load();
        })
    }

    return{
        loadMessage,
        texts,
        messageTotal,
        messageRead,
        messageUnread,
        isLoading,
        loadAdminMessages,
        updateSeen,
        sendMessage,
        deleteMessage,
    }
}