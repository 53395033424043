import useLoadAccount from "../hooks/useLoadAccounts";
import { useEffect, useState } from "react";
import useProcessTransactions from "../hooks/useProcessTransactions";

const DepositModal = (props) => {
    const {loadAccount, accounts} = useLoadAccount();
    const {deposit, isLoading} = useProcessTransactions();

    const [selectedName, setSelectedName] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [amount, setAmount] = useState(0);
    const [profit, setProfit] = useState(0);
    const spin = <i className="fa fa-spinner fa-spin"></i>;

    let max;
    let min;
    let increment;
    let days;
    if (props.plan ==="initial"){
        min = 30;
        max = 999;
        increment = 0.06;
        days= 1;
    }
    else if (props.plan ==="mining"){
        min = 1000;
        max = 9999;
        increment = 0.15;
        days= 1;
    }
    else{
        min = 10000;
        max = 99999999999999999;
        increment = 0.3;
        days= 3;
    }
    const calculateProfit = (event) => {
        const newValue = parseFloat(event.target.value);
        setAmount(newValue);
        setProfit(((newValue * increment) + newValue) * days);
    };

    const handleChange = (event) => {
        setSelectedName(event.target.selectedOptions[0].getAttribute('name'));
        setSelectedValue(event.target.selectedOptions[0].getAttribute('value'));
    };
    const submit = (e) => {
        e.preventDefault();
        let data ={
            userID: props.userID,
            plan: props.plan,
            type: "deposit",
            amount: amount,
            coin: selectedName,
            walletID: selectedValue,
        }
        const mailData = {
            name: props.name,
            email: props.email,
            message: `Your deposit of $${amount} is been processed and will be confirmed within the next 24hrs`,
            subject: "Deposit Request has been recieved",
        }
        if(selectedValue){
            deposit(data,mailData)
        }
    }

    useEffect(() => {
      loadAccount();
    }, [loadAccount])
    

    return(
        <div className="modal fade" id="depositModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content myModal">
            <div className="modal-header">
                <h1 className="modal-title fs-5 text-uppercase" id="exampleModalLabel">{props.plan} Plan</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <form onSubmit={submit}>
                <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Enter Amount</label>
                <div className="form-text text-danger"><span>Minimum amount is {min} and Maximum amount is {props.plan ==="galaxy"? "unlimited": max}</span></div>
                <input type="number" value={amount} onChange={calculateProfit} className="form-control form-control-lg" id="exampleFormControlInput1" min={min} max={max} required/>
                </div>

                <div className="mb-3">
                <label className="form-label">Select Wallet</label>
                <select id="accounts" className="form-control form-select-lg" aria-label=".form-select-lg"  onChange={handleChange} required>
                {accounts}
                </select><br></br>

                <small>Wallet Address</small>
                <p>{selectedValue}</p>
                </div>
                
                <div className="input-group input-group-lg mb-3">
                <button className="btn btn-outline-secondary" type="button" disabled>Earnings</button>
                <input readOnly type="text" value={profit} className="form-control" placeholder="Total Profit" aria-label="Recipient's username" aria-describedby="button-addon2" />
                <button className="btn btn-danger" type="submit">
                {isLoading ? (<>{spin} Confirming</>) : "Confirm Payment"}
                </button>
                </div>
                </form>
            </div>
            </div>
        </div>
        </div>
    )
}
export default DepositModal;