import { useState } from 'react';
import Loader from '../../components/Loader';
import AdminRow from '../../components/admin/adminRow';


const useLoadAdmin = () => {
  const [list, setList] = useState([]);
  const [isLoading,setIsLoading]= useState(false);

  const loadAdmin = (id) => {
    setIsLoading(true)
    setList(<Loader color='border-light' />);
    fetch("https://account.coin-flex.net/php/downline.php", {
      method: 'post',
      mode: "cors",
      body: JSON.stringify({id:id})
    })
      .then(response => response.json())
      .then(data => {
        const loadList = data.map(field => (
          <AdminRow key={field.id} field={field} loadAdmin={() =>loadAdmin(id)} id={id} />
        ));
        setList(loadList);
        setIsLoading(false)
      });
  };

  return {
    list,
    loadAdmin,
    isLoading,
  };
};

export default useLoadAdmin;
