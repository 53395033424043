const Navbar = () => {
 

const toogle_sidebar =()=>{
    document.body.classList.toggle('sb-sidenav-toggled');
}
return (
    <nav className="navbar navbar-expand navbar-light" style={{backgroundColor:"#f6f6f9"}}>
    <div className="container-fluid">
    <a className="nav-link ms-1 me-2 mt-1" href="#!" role="button" onClick={toogle_sidebar}>
    <button className="navbar-toggler d-block d-md-none d-lg-none" type="button" aria-expanded="false" aria-label="Toggle navigation"><i className="bi bi-list"></i></button>
    </a>
    
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
    </div>
    </div>
    </nav>
);
}
export default Navbar;