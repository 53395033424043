const Loader = (prop) => { 
    const border = prop.color;
    return (
    <div className="ms-4 ms-md-0 text-start">
    <div className="loader bg-transparent">
        <div className={`box-1 ${border}`}></div>
        <div className={`box-2 ${border}`}></div>
        <div className={`box-3 ${border}`}></div>
        <span className="visually-hidden">Loading...</span>
    </div>
    </div>
    )
}
export default Loader