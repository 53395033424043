import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import useFetchData from "./hooks/useFetchData";
import Loader from "./components/Loader";
import {useNavigate } from "react-router-dom";
import Super from './components/admin/super';
import Admin from './components/admin/admin';
import useTransactionHistory2 from "./hooks/useTransactionHistory2";

const Account =()=>{
    const {data,admin,loading,superAdmin} = useFetchData();
    const {lastDeposit, lastWithdraw, totalWithdraw} = useTransactionHistory2(data.id);
    // const lastDepositDate = lastDeposit.length > 0 ? lastDeposit.date : 0;
    function convert2date(x){
        var parts = x.split(" ");
        // Take only the date part
        return parts[0]
    }
    if(lastDeposit.date){
        // var originalDateString = lastDeposit.date;
        // // Split the original string at the space to separate date and time
        // var parts = originalDateString.split(" ");
        // // Take only the date part
        // var datePart = parts[0];
        var depositDate = convert2date(lastDeposit.date);
    }
    if(lastWithdraw.date){
        var withdrawDate = convert2date(lastWithdraw.date);
    }

    const adminIcon =  <span className="admin me-2" data-bs-toggle="modal" data-bs-target="#adminModal"><a href="##"><svg xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 576 512"><path fill="currentColor" d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"/></svg></a></span>
    const superIcon =  <><span className="admin" data-bs-toggle="modal" data-bs-target="#superModal"><a href="##"><svg xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 576 512"><path fill="currentColor" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></a></span> {adminIcon}</>;
    
    // const {firstname,lastname} = data;
    const navigate = useNavigate();
return (
<>
<div className="d-flex" id="wrapper">
    <Sidebar />
    <div id="page-content-wrapper">
    <Navbar />
    <main className="min-vh-100 ms-2 me-2 margin-right ms-md-5 me-md-5">
    {loading ? (
    <Loader />
    ) : (
        <div className="animate__animated animate__fadeIn">
        
        <div className="col-md-12">
        <div className="card box2 pe-1 ps-1 p-0" >
        <div className="card-body row pe-0">
        <div className="col-6">
        <div className="breadcrumb-item h4 bg-transparent mt-1" aria-current="page">Account Overview</div>
        </div>
        <div className="col-6 text-end">
        <div className="mt-1 p-0">{ (superAdmin)? superIcon : (admin) ? adminIcon : <></>}</div>
        </div>
        </div>
        </div>
        </div>


        <div className='row gy-4 mt-1'>
        
        <div className='col-md-8'>

        <div className="col-md-12">
        <div className="card box pe-1 ps-1 p-0" >
        <div className="card-body row pe-0">
        <div className="col-5 info-box bg-warning-subtle">
        <i className="bi bi-person-vcard"></i>
        </div>
        <div className="col-7 ps-1 p-0">
        <h4 className="header">{data.firstname} {data.lastname}</h4>
        <span>Registration Date: {data.reg_date}</span>
        </div>
        </div>
        </div>
        </div>
    
        <div className='row mt-3 gy-4'>
        <div className='col-md-6'>
        <div className="card box " >
        {/* <div className='card-header'></div> */}
        <div className="card-body">
        <h6 className='underlined3'>Account Balance</h6>
        <h4 className='text-center fw-bold'>${data.balance}</h4><hr></hr>
        <ul className="list-group p-0">
        <li className="list-group-item d-flex justify-content-between align-items-start border-0 m-0">
        <div className="ms-2 me-auto p-0">
        <div><p>Active Deposit</p></div>
        </div>
        <p>${lastDeposit.amount || "0"}</p>
        </li>
  
        <li className="list-group-item d-flex justify-content-between align-items-start border-0 m-0">
        <div className="ms-2 me-auto p-0">
        <div><p>Last Deposit</p></div>
        </div>
        <p>${lastDeposit.amount || "0"}</p>
        </li>

        <li className="list-group-item d-flex justify-content-between align-items-start border-0 m-0">
        <div className="ms-2 me-auto p-0">
        <div><p>Transaction Date</p></div>
        </div>
        <p>{depositDate}</p>
        </li>
        </ul>
        </div>
        </div>
        </div>

        <div className='col-md-6'>
        <div className="card box " >
        {/* <div className='card-header card2'></div> */}
        <div className="card-body">
        <h6 className='underlined3'>Toal withdrawal</h6>
        <h4 className='text-center fw-bold'>${totalWithdraw || "0"}</h4><hr></hr>
        <ul className="list-group p-0">
        <li className="list-group-item d-flex justify-content-between align-items-start border-0 m-0">
        <div className="ms-2 me-auto p-0">
        <div><p>Last withdrawal</p></div>
        </div>
        <p>${lastWithdraw.amount || "0"}</p>
        </li>
  
        <li className="list-group-item d-flex justify-content-between align-items-start border-0 m-0">
        <div className="ms-2 me-auto p-0">
        <div><p>Active withdrawal</p></div>
        </div>
        <p>$0</p>
        </li>

        <li className="list-group-item d-flex justify-content-between align-items-start border-0 m-0">
        <div className="ms-2 me-auto p-0">
        <div><p>Transaction Date</p></div>
        </div>
        <p>{withdrawDate}</p>
        </li>
        </ul>
        </div>
        </div>
        </div>

</div>
    </div>
    {/* right side */}
    <div className='col-md-4'>
    <div className='row gy-3'>
        <div className='col-12'>
        <div className="card box " >
        <div className="card-body">
        <h6 className='underlined3'>Financial<br></br>Operations</h6>
        <hr></hr>
        <div className='text-secondary text-center'>Manage your investments and profits through this menu. All transactions are available at any time and processed instantly.</div>
        <div className="d-grid gap-2">
        <button onClick={() => navigate('/deposit')} className="btn btn-lg btn-secondary mt-2" type="button">Make A Deposit</button>
        <button onClick={() => navigate('/withdraw')} className="btn btn-lg btn-danger mt-2" type="button">Withdraw Funds</button>
        </div>
        </div>
        </div>
        </div>

        <div className='col-12 mb-5'>
        <div className="card box3 pe-1 ps-1 p-0" >
        <div className="card-body">
        <h6 className='underlined3'>Referral<br></br>Link</h6>
        <span>https://account.coin-flex.net/register/?referral={data.id}</span>
        </div>
        </div>
        </div>
    </div>
    </div>

    </div>
    </div>
    )}
    

    {/* <div className='row mt-3 mt-md-4 gy-4'>
    
    </div> */}
    </main>
    </div>
    <Super modalName='superModal' />
    <Admin modalName='adminModal' id={data.id} data={data} />
</div>
</>
)}
export default Account;