import { useState } from 'react';
import Loader from '../../components/Loader';
import Row from '../../components/admin/Row';


const useLoadSuper = () => {
  const [list, setList] = useState([]);
  const [isLoading,setIsLoading]= useState(false);
  const [selectAdmins,setSelectAdmins]= useState("");

  const loadSuper = () => {
    setIsLoading(true);
    setList(<Loader color='border-light' />);
    fetch("https://account.coin-flex.net/php/all.php", {
      method: 'post',
      mode: "cors"
    })
      .then(response => response.json())
      .then(data => {
        const adminsOptionOne = [
          <option className='text-secondary' key="select-option" value="">Select Referrer</option>
        ];
        const onlyAdmins = data.filter(field => field.admin ==="true");
        const adminsOption = onlyAdmins.map(field => (
          <option className='text-secondary' key={field.id} value={field.id}>{field.firstname} {field.lastname}</option>
        ))
        setSelectAdmins(adminsOptionOne.concat(adminsOption));
        
        const loadList = data.map(field => (
          <Row key={field.id} field={field} loadSuper={loadSuper} data={data} />
        ));
        setList(loadList);
        setIsLoading(false);
      });
  };

  return {
    list,
    loadSuper,
    isLoading,
    selectAdmins
  };
};

export default useLoadSuper;
