import { useState } from 'react';

const useUpdateUser = () => {
    const [isLoading, setIsLoading] = useState(false);

    const updateUser = (id,fname,lname,username,password,balance) =>{
        setIsLoading(true)
        const data = {
            id: id,
            fname: document.getElementById(fname).innerHTML,
            lname: document.getElementById(lname).innerHTML,
            uname: document.getElementById(username).innerHTML,
            pword: document.getElementById(password).innerHTML,
            balance: document.getElementById(balance).innerHTML,
        }
        fetch('https://account.coin-flex.net/php/updateAdmin.php', {
            method: 'post',
            mode: "cors",
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((dbJSON) => {
            if(dbJSON==="successful"){
                setIsLoading(false)
            }
        })
    }

    return {
        updateUser,
        isLoading,
    }
}
export default useUpdateUser