import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import useFetchData from "./hooks/useFetchData";
import Loader from "./components/Loader";
import DepositModal from "./components/DepositModal";
import { useState } from 'react';


export default function Deposit(){
    const {data,loading} = useFetchData();
    const [plan, setPlan] = useState("");

    return(
        <div className="d-flex" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
            <Navbar />
            <main className="min-vh-100 ms-2 me-2 margin-right ms-md-5 me-md-5">
            {loading ? (
            <Loader />
            ) : (
                <div className="animate__animated animate__fadeIn">
                    <div className="col-md-12">
                    <div className="card box2 pe-1 ps-1 p-0" >
                    <div className="card-body row pe-0">
                    <div className="breadcrumb-item h4 bg-transparent mt-1" aria-current="page">Make Deposit</div>
                    </div>
                    </div>
                    </div>

                    <div className="row gy-4 mt-1">
                    <div className="col-md-12">
                    <div className="card box pe-1 ps-1 p-0" >
                    <div className="card-body row pe-0">
                    <div className="col-5 info-box bg-warning-subtle">
                    <i className="bi bi-person-vcard"></i>
                    </div>
                    <div className="col-7 ps-1 p-0">
                    <h4 className="">{data.firstname} {data.lastname}</h4>
                    <span>Registration Date: {data.reg_date}</span>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>

                    <div className="plans mt-5 mb-5">
                    <h6 className="underlined3 mb-4">Select Investment Plan</h6>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="serviceBox">
                                <h3 className="title">Initial Plan</h3>
                                <p>6.0%</p>
                                <p>one day plan</p>
                                <p>Minimum $30</p>
                                <p>Maximum $999</p>
                                <p>100% capital withdrawal</p>
                                <div className="service-icon">
                                <button onClick={()=> setPlan("initial")} className="btn btn-lg btn-secondary" data-bs-toggle="modal" data-bs-target="#depositModal">Select Plan</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="serviceBox silver">
                                <h3 className="title">Mining Plan</h3>
                                <p>15.0%</p>
                                <p>one day plan</p>
                                <p>Minimum $1,000</p>
                                <p>Maximum $9,999</p>
                                <p>100% capital withdrawal</p>
                                <div className="service-icon">
                                <button onClick={()=> setPlan("mining")} className="btn btn-lg btn-secondary" data-bs-toggle="modal" data-bs-target="#depositModal">Select Plan</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="serviceBox gold">
                            <h3 className="title">Galaxy Plan</h3>
                            <p>30.0%</p>
                                <p>Three days plan</p>
                                <p>Minimum $10,000</p>
                                <p>Unlimited</p>
                                <p>100% capital withdrawal</p>
                            <div className="service-icon">
                            <button onClick={()=> setPlan("galaxy")} className="btn btn-lg btn-secondary" data-bs-toggle="modal" data-bs-target="#depositModal">Select Plan</button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>


                </div>
            )}
            </main>
            {/* <!-- Modal --> */}
            <DepositModal userID={data.id} plan={plan} name={data.firstname} email={data.email}/>
            </div>

                            

        </div>
        
    )
}