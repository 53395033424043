import useLoadSuper from "../../hooks/admin/useLoadSuper";
import Account from "./Account";


const Super = (props) =>{

const {list, loadSuper,isLoading,selectAdmins} = useLoadSuper();

return (
<div className="modal fade" id={props.modalName} tabIndex="-1" aria-labelledby="superModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-fullscreen">
    <div className="modal-content bg-modal p-0">
      <div className="modal-body p-0">

    <div className="row m-0">
        <div className="col-12 p-0 m-0">
            <div className="panel ">
                <div className="panel-heading">
                    <div className="row">
                        <div className="col col-sm-3 col-12">
                            <h4 className="title">Super Admin</h4>
                        </div>
                        <div className="col-sm-9 col-12 text-sm-end">
                            <div className="btn_group">
                                <input type="text" className="form-control me-1" placeholder="Search"/>
                                <button className="btn btn-default me-1" onClick={loadSuper}><i className={isLoading?"fa fa-spinner fa-spin": "fa fa-sync-alt"}></i></button>
                                <button className="btn btn-default" title="Close" data-bs-dismiss="modal"><i className="fa-solid fa-x"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-body table-responsive table-responsive-super min-vh-100">
                    <table className="table" >
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Country</th>
                                <th>Username</th>
                                <th>Password</th>
                                <th>Referrer</th>
                                <th></th>
                                <th>Balance</th>
                                <th>Action</th>
                                <th>Admin</th>
                                <th>Send Message</th>
                                <th></th>
                                <th>Load Messages</th>
                                <th></th>
                                <th>Load History</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {list}
                        </tbody>
                    </table>

                    <Account />

                </div>
            </div>
        </div>
    </div>

      </div>
    </div>
  </div>
</div>
)
}
export default Super;