import useAccounts from "../../hooks/useAccounts";
import { useState } from "react";
import AccountModal from "./AccountsModal";
import AssignRefModal from "./AssignRefModal";


const AssignRef = (props) =>{
    const [displayAssignModal, setDisplayAssignModal] = useState(false);

    function showAssignModal() {
        setDisplayAssignModal(true);
    }
    function closeAssignModal(){
        setDisplayAssignModal(false);
    }

    return(
        <><button className="ms-2 mt-2 btn btn-outline-info" type="button" onClick={showAssignModal}>
        <i className="bi bi-person-plus"></i>
        </button>

        <AssignRefModal load={props.load} userID={props.userID} name={props.name} display={displayAssignModal} close={closeAssignModal} />
        </>
    )

}
export default AssignRef;