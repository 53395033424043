const Checked = (props) =>{
    return (
        <div className="checkbox"><input type="checkbox" onClick={props.handleCheck} id={props.id} checked={props.checked} onChange={props.handleChange}/>
        <label htmlFor={props.id} ></label></div>
    )
}

const UnChecked = (props) =>{

    return (
        <div className="checkbox"><input type="checkbox" id={props.id} onClick={props.handleCheck} />
        <label htmlFor={props.id}></label></div>
    )
}

export {Checked, UnChecked}