const useAdminStatus = () => {

    const adminStatus = async (data) =>{
        await fetch('https://account.coin-flex.net/php/adminstatus.php', {
            method: 'post',
            mode: "cors",
            body: JSON.stringify(data)
        })
    }
    return {
        adminStatus
    }
}
export default useAdminStatus;