 import useHandleInputs from "../../hooks/useHandleInputs";
 import useMessage from "../../hooks/useMessage";

 const CreateMessage = (props) => {
    const {handleInputs, values} = useHandleInputs();
    const {sendMessage, isLoading} = useMessage();

    function send(e){
        e.preventDefault();
        values["userID"] = props.data.id
        sendMessage(values);
    }

    return(
        <div id="myModal" className="modal2 animate__animated animate__fadeIn" style={{ display: props.display? "block":"none" }}>
        <div className="modal-content2 panel rounded-4">

        <div className="panel-footer">
        <div className="btn_group text-end">
        <button type="button" className="btn border-0 btn-default" onClick={props.close}><i className="fa-solid fa-x"></i></button>
        </div>
        </div>

        <div className="modal-body  text-light">
            <form className="form-inline" onSubmit={send}>
                
            <div className="row">
            <div className="col-12 text-end">
            <span className="text-info fs-5"><i class="bi bi-person-circle me-2"></i></span><span className="text-light fw-slight-bold">{props.name}</span>
            </div>
            </div>
            <hr></hr>

            <div className="row gy-3">
            <div className="col-12">
            <label htmlFor="firstname">Subject</label>
            <input type="text" value={values.subject || ""} name="subject" onChange={handleInputs} required className="form-control bg-light mt-2" />
            </div>
            <div className="col-12">
            <label htmlFor="lastname">Message</label>
            <textarea rows="5" value={values.message || ""} name="message" onChange={handleInputs} required className="form-control bg-light mt-2" />
            </div>
            <div className="panel-footer"><hr></hr>
            <div className="btn_group text-end">
            <button type="submit" disabled={isLoading} required className="btn btn-default"><i className={isLoading? "fa fa-spinner fa-spin" : "fa fa-plus"}></i></button>
            </div>
            </div>
            </div>
            </form>
        </div>
        </div>
        </div>
    )

 }
 export default CreateMessage;