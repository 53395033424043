import { useState } from "react";
import Loader from "../components/Loader";

export default function useAccounts(){
    const [isLoading,setIsLoading]= useState(false);
    const [deleteLoading,setDeleteLoading]= useState(false);
    const [accounts, setAccounts] = useState("");


    const displayAccounts = () =>{
        setIsLoading(true);
        setAccounts("");
        fetch("https://account.coin-flex.net/php/displayAccount.php", {
          method: 'post',
          mode: "cors"
        })
          .then(response => response.json())
          .then(data => {
            const loadList = Array.isArray(data) && data.map(field => (
                <>
                <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto text-light">
                <small className="fw-bold h6">{field.coin}</small>
                <br></br>
                <small className="mt-1">{field.walletID}</small></div>
                <span><button id={field.id} disabled={isLoading} type="button" onClick={() => deleteAccount(field.id)} className="mt-1 btn btn-sm btn-danger">Delete</button></span>
                </li>
                <hr></hr>
                </>
            ));
            setAccounts(loadList);
            setIsLoading(false);
        });
    }

    function deleteAccount(id){
        setDeleteLoading(true);
        fetch('https://account.coin-flex.net/php/deleteAccount.php', {
            method: 'post',
            mode: "cors",
            body: JSON.stringify({id:id})
        })
        .then(response => response.json())
        .then(data =>{
            if(data==='successful'){
                displayAccounts();
                setDeleteLoading(false)
            }
        })
    }
    function addAccount(data){
        setDeleteLoading(true);
        fetch('https://account.coin-flex.net/php/createAccount.php', {
            method: 'post',
            mode: "cors",
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data =>{
            if(data==='successful'){
                setDeleteLoading(false);
            }
        })
    }

    return{displayAccounts, isLoading, accounts,deleteAccount,addAccount,deleteLoading}
}