import useProcessTransactions from "../../hooks/useProcessTransactions";

const AdminHistory = (props) =>{
    const {isLoading, confirm, confirmWithdraw, cancelWithdraw, cancelDeposit} = useProcessTransactions();
    const data = props.data;
    
    const loadHistory = Array.isArray(data) && data.map(field => {
        const data2 ={
            id: field.id,
            userID: field.userID,
            balance: parseInt(props.balance, 10) + parseInt(field.amount, 10),
            plan: field.plan
        }
        const withdrawData ={
            id: field.id,
        }

        const cancelWithdrawData ={
            id: field.id,
            userID: field.userID,
            balance: parseInt(props.balance, 10) + parseInt(field.amount, 10),
        }
        const cancelDepositData ={
            id: field.id
        }

        const mailData = {
            name: props.firstname,
            email: props.email,
            message: `Your Deposit transaction of $${field.amount} has been processed and confirmed`,
            subject: "Deposit confirmed",
        }

        const mailData2 = {
            name: props.firstname,
            email: props.email,
            message: `Your Withdrawal request of $${field.amount} has been processed`,
            subject: "Withdrawal Processed",
        }
        
            return (
                <>
                <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto text-light">
                <small className="fw-bold h6">{field.type}</small>
                <br></br>
                <small className="mt-1">Wallet: {field.coin}</small><br></br>
                <small className="mt-1">Address: {field.walletID}</small>
                <br></br>
                <small className="text-light">${field.amount}</small><br></br>
                <small className={field.phase ==="pending" || field.phase ==="cancelled" ?"text-danger":"text-success"}>{field.phase}</small>
                <br></br>

                <div className={(field.type!=="deposit" || field.phase==="confirmed" || field.phase==="cancelled")? "d-none":""}>
                <button id={field.id} disabled={isLoading} type="button" onClick={() => confirm(data2,props.load,mailData)} 
                 className="mt-1 btn btn-sm btn-outline-info me-3">
                 Confirm
                 </button>

                 <button disabled={isLoading} type="button" onClick={() => cancelDeposit(cancelDepositData,props.load)} className="mt-1 btn btn-sm btn-outline-danger">
                    Cancel</button>
                 </div>

                 
                 <div className={(field.type!=="withdrawal" || field.phase==="confirmed" || field.phase==="cancelled")? "d-none":""}>
                 <button id={'btn'+field.id}  disabled={isLoading} type="button" onClick={() => confirmWithdraw(withdrawData,props.load,mailData2)} 
                 className="mt-1 btn btn-sm btn-outline-info me-3">
                 Confirm
                 </button>

                 <button disabled={isLoading} type="button" onClick={() => cancelWithdraw(cancelWithdrawData,props.load)} className="mt-1 btn btn-sm btn-outline-danger">
                 Cancel</button>
                 </div>

                </div>
                <span className="">{field.date}</span>
                </li>
                <hr></hr>
                </>
            )
    })
   
return(
    <>
    {loadHistory}
    </>
)
}
export default AdminHistory;