import Loader from "../Loader";
import useAccounts from "../../hooks/useAccounts";
import { useState } from "react";
import useLoadSuper from "../../hooks/admin/useLoadSuper";
import useUpdateRef from "../../hooks/admin/useUpdateRef";

const AssignRefModal = (props) =>{
    const [selectedName, setSelectedName] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const {loadSuper,isLoading,selectAdmins} = useLoadSuper();
    const {updateRef,isRefLoading} = useUpdateRef();

    const handleChange = (event) => {
        setSelectedName(event.target.selectedOptions[0].getAttribute('name'));
        setSelectedValue(event.target.selectedOptions[0].getAttribute('value'));
    };

    const submit =()=>{
        updateRef({
            refID: selectedValue,
            userID: props.userID
        },props.load)
    }

  return(
        <div id="myModal" className="modal2 animate__animated animate__fadeIn" style={{ display: props.display? "block":"none" }}>
        <div className="modal-content2 panel rounded-4">

        <div className="panel-footer row">
        <div className="col-6 btn_group text-start">
        <button className="btn btn-default me-1" onClick={loadSuper}><i className={isLoading?"fa fa-spinner fa-spin me-1": "fa fa-sync-alt me-1"}></i>Load Admins</button>
        </div>

        <div className=" col-6 btn_group text-end">
        <button type="button" className="btn border-0 btn-default" onClick={props.close}><i className="fa-solid fa-x"></i></button>
        </div>
        
        </div>

        <div className="modal-body  text-light">
        <div className="row">
        <div className="col-12 text-center">
        <span className="text-info fs-5"><i class="bi bi-person-circle me-2"></i></span><span className="text-light fw-slight-bold">{props.name}</span>
        </div>
        </div>
        <hr></hr>
        { isLoading? ( <Loader color='border-light' />
        ) :(
            <select id="accounts" className="form-control form-select-lg bg-transparent text-light" aria-label=".form-select-lg"  onChange={handleChange} required>
                {selectAdmins}
            </select>
        )}
        
        </div>

        <div className="panel-footer row">

        <div className=" col-6 btn_group text-start">
        </div>
        <div className="col-6 btn_group text-end">
        <button className="btn btn-default me-1" onClick={submit}><i className={isRefLoading?"fa fa-spinner fa-spin me-1": "fa fa-sync-alt me-1"}></i>Assign Referrer</button>
        </div>
        
        </div>
        </div>
        </div>
  )   
}
export default AssignRefModal;