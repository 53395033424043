import { useState } from 'react';

const useUpdateRef = () => {
    const [isRefLoading, setIsLoading] = useState(false);

    const updateRef = (data,load) =>{
        setIsLoading(true)
        fetch('https://account.coin-flex.net/php/updateRef.php', {
            method: 'post',
            mode: "cors",
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((dbJSON) => {
            if(dbJSON==="successful"){
                setIsLoading(false);
                load();
            }
        })
    }

    return {updateRef,isRefLoading}
}
export default useUpdateRef;