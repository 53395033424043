import './css/App.css';

function ErrorPage() {
  return (
    <div id='error-page' className='height404'>     
        <div className='content-404'>
            <h1>404</h1>
            <h2>Not Found</h2>
            <p>The resource requested could not be found on this server!</p>
        </div>
    </div>
  );
}

export default ErrorPage;
