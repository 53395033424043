import Loader from "../Loader";
import AdminHistory from "./AdminHistory";
import useTransactionHistory from "../../hooks/useTransactionHistory";

const ConfirmDeposit = (props) =>{
    const {transationsHistory, transactionData, isLoading} = useTransactionHistory();
    
  return(
        <div id="myModal" className="modal2 animate__animated animate__fadeIn" style={{ display: props.display? "block":"none" }}>
        <div className="modal-content2 panel rounded-4">
        <div className="panel-footer row">
        <div className="col-6 btn_group text-start">
        <button className="btn btn-default me-1" onClick={() => transationsHistory(props.id)}><i className={isLoading?"fa fa-spinner fa-spin": "fa fa-sync-alt"}></i></button>
        </div>

        <div className=" col-6 btn_group text-end">
        <button type="button" className="btn border-0 btn-default" onClick={props.close}><i className="fa-solid fa-x"></i></button>
        </div>

        <div className="col-12 mt-3 btn_group text-center">
        <span className="ms-2"><i class="bi bi-person-circle text-info fs-5 me-1"></i><span className="text-light fw-slight-bold">{props.name}</span></span>
        </div>
        
        </div>

        <div className="modal-body  text-light">
        { isLoading? ( <Loader color='border-light' />
        ) :(
          <AdminHistory data={transactionData} balance={props.balance} firstname={props.firstname} email={props.email} userID={props.id} load={() => transationsHistory(props.id)} />
        )}
        </div>
        </div>
        </div>
  )   
}
export default ConfirmDeposit;