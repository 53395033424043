import { useState } from "react";

export default function useTransactionHistory(){

    const [recentList, setRecentList] = useState("");
    const [transationList, setTransactionList] = useState("");
    const [transactionData, setTransactionData] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const ListDisplay = (props) =>{
        const {id,plan,type,amount,date,coin,walletID,phase,confirm_date} = props.field;
        return (
          <>
            <div key={id} className="d-flex flex-wrap align-items-center mb-3">
            <div className="d-flex align-items-center">
            <div>
            <small className="uppercase">{plan} plan</small><br></br>
            <small className="text-muted">{type}</small><br></br>
            <small>Status: <span className={phase ==="pending" || phase ==="cancelled"? "text-danger":"text-success"}>{phase}</span></small><br></br>
            <small>Wallet: {coin}</small><br></br>
            <small>Address: {walletID}</small><br></br>
            <small>Confirmation Date: {confirm_date==="0"? "Not Confirmed":confirm_date}</small>
            </div>
            
            </div>
            
            <div className="ms-auto">
            <small className="d-block text-end">{date}</small>
            {type!=='deposit'? <strong className="d-block text-danger text-end"><span className="me-1">-</span> ${amount}</strong> : <strong className="d-block text-end text-success"><span className="me-1">+</span> ${amount}</strong>}
            </div>
            </div>
            <hr></hr>
            </>
        )
    }

    const transationsHistory = (id) => {     
      setIsLoading(true); 
        const data ={
        userID: id
        };
        fetch("https://account.coin-flex.net/php/displayhistory.php",{
          method: 'post',
          mode: "cors",
          body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
          if(data!=='0'){
            setIsLoading(false);
              const sorted = data.sort((a,b) => b.id-a.id);
              setTransactionData(sorted);
              // DISPLAY ALL TRANSACTION HISTORY
              const list = sorted.map(field => {
                // setTransactionData(list);
                return (
                    <ListDisplay field={field} />               
                )
              });

              // DISPLAY ONLY THE RECENT FOUR
              const recentFour = sorted.slice(0,4); 
              const recentFourList = recentFour.map(field => {
                return (
                    <ListDisplay field={field} />               
                )
              });

              setTransactionList(list);
              setRecentList(recentFourList);
          }
          else{
            setIsLoading(false);
          }
        })
    }

    return{
        transationsHistory,
        recentList,
        transationList,
        transactionData,
        isLoading
    }

}