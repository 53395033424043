import { useState } from "react";
import "./css/style.css";
import "./css/fontAwesome.css"
import logo from './img/logo.png';
import useHandleInputs from "./hooks/useHandleInputs";
import useRegister from "./hooks/useRegister";
import { useNavigate } from "react-router-dom";
import useGetReferralID from "./hooks/useGetReferralID";
import { useEffect } from "react";

const Register = () => {
    const eye = <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" fill='currentColor'><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"/></svg>;
    const eyeSlash = <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" fill='currentColor'><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm9.4 130.3C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5l-41.9-33zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z"/></svg>;
    const [showPassword, setShowPassword] = useState(false);
    const spin = <i className="fa fa-spinner fa-spin"></i>;
    const {register, isLoading, formResponse} = useRegister();
    const {handleInputs, values, checkUsername, checking, checkResponse, checked, checkEmail, checkPassword, emailResponse, pwordResponse,resetUsername, resetEmail, resetPassword} = useHandleInputs();
    const navigate = useNavigate();
    const {getReferral, referralID} = useGetReferralID();

    useEffect(() => {
      getReferral();
    },[])

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const submit =(e)=>{
        e.preventDefault();
        values.ancestor_id = referralID;
        register(values,resetUsername, resetEmail, resetPassword)
    }

return(
<div className="container-fluid p-0">
  <div className="row header-top">
    
    <div className="col-6 top-left">
      <ul className="social-icon-two pt-4">
        <li className="title">Follow us</li>
        <li><a href="##"><i className="bi bi-facebook"></i></a></li>
        <li><a href="##"><i className="bi bi-twitter" ></i></a></li>
    </ul>
    </div>
    <div className="col-6 right-background">
      <div className="top-right">
        <div className="text">A platform for your financial growth.</div>
      </div>
    </div>
    
  </div>
    <nav className="navbar sticky-top navbar-expand-md">
        <div className="container">
          <a className="navbar-brand" href="##"><img src={logo} alt="logo" height="55"/></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="offcanvas offcanvas-end mobile-nav-bg text-bg-dark" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"><a className="navbar-brand" href="##"><img src={logo} alt="logo" height="40"/></a></h5>
              <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body m-0 p-0">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">

              </ul>

              {/* <!--
    ====================================================================
		NORMAL NAV
    ====================================================================
  --> */}
              <div className="d-flex d-none d-md-block">
                <ul className="navbar-nav me-auto my-2 my-lg-0 navs" >
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/index.html">Home</a>
  
                  </li>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/about.html">About Us</a>
  
                  </li>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/plan.html">Plans</a>
  
                  </li>
  
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/faq.html">FAQs</a>
  
                  </li>
  
                  <li className="nav-item"><a className="nav-link" onClick={() => navigate('/')} href="#!">Log In</a>
  
                  </li>
                  <li className="nav-item current"><a className="nav-link" onClick={() => navigate('/register')} href="#!">Sign Up</a></li>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/contact.html">Contact Us</a></li>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/rules.html">Terms & Conditions</a></li>
                </ul>
              </div>

              {/* <!--
    ====================================================================
		MOBILE NAV
    ====================================================================
  --> */}
              <div className="d-flex d-sm-block d-md-none">
                <ul className="navbar-nav me-auto my-2 my-lg-0 mobile-navs" style={{width: "100%"}}>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/index.html">Home</a>
  
                  </li>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/about.html">About Us</a>
  
                  </li>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/plan.html">Plans</a>
  
                  </li>
  
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/faq.html">FAQs</a>
  
                  </li>
  
                  <li className="nav-item"><a className="nav-link" onClick={() => navigate('/')} href="#!">Log In</a>
  
                  </li>
                  <li className="nav-item current"><a className="nav-link" onClick={() => navigate('/register')} href="#!">Sign Up</a></li>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/contact.html">Contact Us</a></li>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/rules.html">Terms & Conditions</a></li>
                </ul>
              </div>

            </div>
          </div>
 
        </div>
      </nav>
      {/* <!--
    ====================================================================
		LOGIN SECTION
    ====================================================================
  --> */}
  <div className="form-bg">
    <div className="container">
        <div className="row">
            <div className="">
                <div className="form-container">
                    <div className="full-content p-3 p-md-4">
                        <h2 className="underlined">Sign Up</h2>
                        <form className="form-horizontal mt-3" onSubmit={submit}>
                        <div className="form-text text-danger text-center"><span>{formResponse}</span></div>
                            <div className="row">

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input type="text" className="form-control form-control-lg" value={values.firstname || ""} name="firstname" onChange={handleInputs} required />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control form-control-lg" value={values.lastname || ""} name="lastname" onChange={handleInputs} required />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Username</label>
                                        <div className="input-group border border-1 rounded">
                                          <input type="text" className="form-control form-control-lg" value={values.username || ""} name="username" onChange={handleInputs} onBlur={()=>checkUsername(values.username)} required/>
                                          {checked ? <span className="input-group-text border border-0 bg-transparent animate__animated animate__fadeInLeft animate__faster"><svg xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 512 512"><path fill='#388E3C' d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg></span> : <></>} 
                                        </div>
                                        {checking? (<div className='form-text'><span className="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span> Checking Availability</div> ) : (<></>)} 
                                        <div id="help" className="form-text text-danger"><span>{checkResponse}</span></div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" className="form-control form-control-lg" value={values.password || ""} name="password" onChange={handleInputs} required/>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Re-Enter Password</label>
                                        <input type="password" className="form-control form-control-lg" value={values.repassword || ""} name="repassword" onChange={handleInputs} onBlur={() => checkPassword(values.password,values.repassword)} required/>
                                        <div className="form-text text-danger"><span>{pwordResponse}</span></div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email" className="form-control form-control-lg" value={values.email || ""} name="email" onChange={handleInputs} onBlur={() => checkEmail(values.email)} required/>
                                        <div className="form-text text-danger"><span>{emailResponse}</span></div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Country</label>
                                        <select id="update-country" className="form-control form-select-lg" aria-label=".form-select-lg example" value={values.country || ""} name="country" onChange={handleInputs} required>
                                        <option>Select Country</option>
                                        <option value="Afghanistan">Afghanistan</option>
                                        <option value="Albania">Albania</option>
                                        <option value="Algeria">Algeria</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Angola">Angola</option>
                                        <option value="Anguilla">Anguilla</option>
                                        <option value="Antarctica">Antarctica</option>
                                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                        <option value="Argentina">Argentina</option>
                                        <option value="Armenia">Armenia</option>
                                        <option value="Aruba">Aruba</option>
                                        <option value="Australia">Australia </option>
                                        <option value="Austria">Austria</option>
                                        <option value="Azerbaijan">Azerbaijan</option>
                                        <option value="Bahamas">Bahamas</option>
                                        <option value="Bahrain">Bahrain</option>
                                        <option value="Bangladesh">Bangladesh </option>
                                        <option value="Barbados">Barbados</option>
                                        <option value="Belarus">Belarus</option>
                                        <option value="Belau">Belau</option>
                                        <option value="Belgium">Belgium</option>
                                        <option value="Belize">Belize</option>
                                        <option value="Benin">Benin</option>
                                        <option value="Bermuda">Bermuda</option>
                                        <option value="Bhutan">Bhutan</option>
                                        <option value="Bolivia">Bolivia</option>
                                        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                        <option value="Botswana">Botswana</option>
                                        <option value="Bouvet Island">Bouvet Island</option>
                                        <option value="Brazil">Brazil </option>
                                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                        <option value="British Virgin Islands">British Virgin Islands</option>
                                        <option value="Brunei">Brunei</option>
                                        <option value="Bulgaria">Bulgaria </option>
                                        <option value="Burkina Faso">Burkina Faso</option>
                                        <option value="Burundi">Burundi</option>
                                        <option value="Cambodia">Cambodia</option>
                                        <option value="Cameroon">Cameroon</option>
                                        <option value="Canada">Canada </option>
                                        <option value="Cape Verde">Cape Verde</option>
                                        <option value="Cayman Islands">Cayman Islands</option>
                                        <option value="Central African Republic">Central African Republic</option>
                                        <option value="Chad">Chad</option>
                                        <option value="Chile">Chile</option>
                                        <option value="China">China </option>
                                        <option value="Colombia">Colombia</option>
                                        <option value="Comoros">Comoros</option>
                                        <option value="Congo Brazzaville">Congo [Brazzaville]</option>
                                        <option value="Congo Kinshasa">Congo [Kinshasa]</option>
                                        <option value="Cook Islands">Cook Islands</option>
                                        <option value="Costa Rica">Costa Rica</option>
                                        <option value="Croatia">Croatia</option>
                                        <option value="Cuba">Cuba</option>
                                        <option value="Curaçao">Curaçao</option>
                                        <option value="Cyprus">Cyprus</option>
                                        <option value="Czech Republic">Czech Republic</option>
                                        <option value="Denmark">Denmark</option>
                                        <option value="Djibouti">Djibouti</option>
                                        <option value="Dominica">Dominica</option>
                                        <option value="Dominican Republic">Dominican Republic</option>
                                        <option value="Ecuador">Ecuador</option>
                                        <option value="Egypt">Egypt</option>
                                        <option value="El Salvador">El Salvador</option>
                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                        <option value="Eritrea">Eritrea</option>
                                        <option value="Estonia">Estonia</option>
                                        <option value="Ethiopia">Ethiopia</option>
                                        <option value="Falkland Islands">Falkland Islands</option>
                                        <option value="Faroe Islands">Faroe Islands</option>
                                        <option value="Fiji">Fiji</option>
                                        <option value="Finland">Finland</option>
                                        <option value="France">France</option>
                                        <option value="French Guiana">French Guiana</option>
                                        <option value="French Polynesia">French Polynesia</option>
                                        <option value="Gabon">Gabon</option>
                                        <option value="Gambia">Gambia</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Ghana">Ghana</option>
                                        <option value="Gibraltar">Gibraltar</option>
                                        <option value="Greece">Greece</option>
                                        <option value="Greenland">Greenland</option>
                                        <option value="Grenada">Grenada</option>
                                        <option value="Guadeloupe">Guadeloupe</option>
                                        <option value="Guatemala">Guatemala</option>
                                        <option value="Guernsey">Guernsey</option>
                                        <option value="Guinea">Guinea</option>
                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                        <option value="Guyana">Guyana</option>
                                        <option value="Haiti">Haiti</option>
                                        <option value="Honduras">Honduras</option>
                                        <option value="Hong Kong ">Hong Kong </option>
                                        <option value="Hungary ">Hungary </option>
                                        <option value="Iceland">Iceland</option>
                                        <option value="India ">India </option>
                                        <option value="Indonesia ">Indonesia </option>
                                        <option value="Iran">Iran</option>
                                        <option value="Iraq">Iraq</option>
                                        <option value="Isle of Man">Isle of Man</option>
                                        <option value="Israel">Israel</option>
                                        <option value="Italy ">Italy </option>
                                        <option value="Ivory Coast">Ivory Coast</option>
                                        <option value="Jamaica">Jamaica</option>
                                        <option value="Japan ">Japan </option>
                                        <option value="Jordan">Jordan</option>
                                        <option value="Kazakhstan">Kazakhstan</option>
                                        <option value="Kenya">Kenya</option>
                                        <option value="Kosovo">Kosovo</option>
                                        <option value="Kuwait">Kuwait</option>
                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                        <option value="Laos">Laos</option>
                                        <option value="Latvia">Latvia</option>
                                        <option value="Lebanon">Lebanon</option>
                                        <option value="Lesotho">Lesotho</option>
                                        <option value="Liberia">Liberia</option>
                                        <option value="Libya">Libya</option>
                                        <option value="Liechtenstein">Liechtenstein</option>
                                        <option value="Lithuania">Lithuania</option>
                                        <option value="Luxembourg">Luxembourg</option>
                                        <option value="Macao S.A.R. China">Macao S.A.R., China</option>
                                        <option value="Macedonia">Macedonia</option>
                                        <option value="Madagascar">Madagascar</option>
                                        <option value="Malawi">Malawi</option>
                                        <option value="Malaysia ">Malaysia </option>
                                        <option value="Maldives">Maldives</option>
                                        <option value="Mali">Mali</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Marshall Islands">Marshall Islands</option>
                                        <option value="Martinique">Martinique</option>
                                        <option value="Mauritania">Mauritania</option>
                                        <option value="Mauritius">Mauritius</option>
                                        <option value="Mayotte">Mayotte</option>
                                        <option value="Mexico">Mexico</option>
                                        <option value="Micronesia">Micronesia</option>
                                        <option value="Moldova">Moldova</option>
                                        <option value="Monaco">Monaco</option>
                                        <option value="Mongolia">Mongolia</option>
                                        <option value="Montenegro">Montenegro</option>
                                        <option value="Montserrat">Montserrat</option>
                                        <option value="Morocco">Morocco</option>
                                        <option value="Mozambique">Mozambique</option>
                                        <option value="Myanmar">Myanmar</option>

                                        <option value="Namibia">Namibia</option>
                                        <option value="Nauru">Nauru</option>
                                        <option value="Nepal">Nepal</option>
                                        <option value="Netherlands">Netherlands</option>
                                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                                        <option value="New Caledonia">New Caledonia</option>
                                        <option value="New Zealand">New Zealand</option>
                                        <option value="Nicaragua">Nicaragua</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Nigeria">Nigeria</option>
                                        <option value="Niue">Niue</option>
                                        <option value="Norfolk Island">Norfolk Island</option>
                                        <option value="North Korea">North Korea</option>
                                        <option value="Norway">Norway</option>
                                        <option value="Oman">Oman</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Palestinian Territory">Palestinian Territory</option>
                                        <option value="Panama">Panama</option>
                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                        <option value="Paraguay">Paraguay</option>
                                        <option value="Peru">Peru</option>
                                        <option value="Philippines">Philippines</option>
                                        <option value="Pitcairn">Pitcairn</option>
                                        <option value="Poland">Poland</option>
                                        <option value="Portugal">Portugal</option>
                                        <option value="Puerto Rico">Puerto Rico</option>
                                        <option value="Qatar">Qatar</option>
                                        <option value="Republic of Ireland">Republic of Ireland</option>
                                        <option value="Reunion">Reunion</option>
                                        <option value="Romania">Romania</option>
                                        <option value="Russia">Russia</option>
                                        <option value="Rwanda">Rwanda</option>
                                        <option value="Saint Helena">Saint Helena</option>
                                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                        <option value="Saint Lucia">Saint Lucia</option>
                                        <option value="San Marino">San Marino</option>
                                        <option value="Sao Tome">Sao Tome</option>
                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                        <option value="Senegal">Senegal</option>
                                        <option value="Serbia">Serbia</option>
                                        <option value="Seychelles">Seychelles</option>
                                        <option value="Sierra Leone">Sierra Leone</option>
                                        <option value="Singapore">Singapore</option>
                                        <option value="Slovakia">Slovakia</option>
                                        <option value="Slovenia">Slovenia</option>
                                        <option value="Solomon Islands">Solomon Islands</option>
                                        <option value="Somalia">Somalia</option>
                                        <option value="South Africa">South Africa</option>
                                        <option value="South Korea">South Korea</option>
                                        <option value="South Sudan">South Sudan</option>
                                        <option value="Spain">Spain</option>
                                        <option value="Sri Lanka">Sri Lanka</option>
                                        <option value="Sudan">Sudan</option>
                                        <option value="Suriname">Suriname</option>
                                        <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                        <option value="Swaziland">Swaziland</option>
                                        <option value="Sweden">Sweden</option>
                                        <option value="Switzerland">Switzerland</option>
                                        <option value="Syria">Syria</option>
                                        <option value="Taiwan">Taiwan</option>
                                        <option value="Tajikistan">Tajikistan</option>
                                        <option value="Tanzania">Tanzania</option>
                                        <option value="Thailand">Thailand</option>
                                        <option value="Timor-Leste">Timor-Leste</option>
                                        <option value="Togo">Togo</option>
                                        <option value="Tokelau">Tokelau</option>
                                        <option value="Tonga">Tonga</option>
                                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                        <option value="Tunisia">Tunisia</option>
                                        <option value="Turkey">Turkey</option>
                                        <option value="Turkmenistan">Turkmenistan</option>
                                        <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                        <option value="Tuvalu">Tuvalu</option>
                                        <option value="Uganda">Uganda</option>
                                        <option value="Ukraine">Ukraine</option>
                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="United States">United States</option>
                                        <option value="Uruguay">Uruguay</option>
                                        <option value="Uzbekistan">Uzbekistan</option>
                                        <option value="Vanuatu">Vanuatu</option>
                                        <option value="Vatican">Vatican</option>
                                        <option value="Venezuela">Venezuela</option>
                                        <option value="Vietnam">Vietnam</option>
                                        <option value="Wallis and Futuna">Wallis and Futuna</option>
                                        <option value="Western Sahara">Western Sahara</option>
                                        <option value="Western Samoa">Western Samoa</option>
                                        <option value="Yemen">Yemen</option>
                                        <option value="Zambia">Zambia</option>
                                        <option value="Zimbabwe">Zimbabwe</option>

                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Referral</label>
                                        <input type="text" className="form-control form-control-lg" value={referralID} disabled/>
                                    </div>
                                </div>

                            </div>
                        
                            <div className="remember-me">
                                <input type="checkbox" className="checkbox"/>
                                <span className="check-label">I agree with <a href="rules.html"> terms and conditions</a></span>
                            </div>
                            <button className="btn signin mt-2" disabled={isLoading}>
                                {isLoading ? (<>{spin} Signing Up</>) : "Register"}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 

  {/* <!--
    ====================================================================
		FOOTER SECTION
    ====================================================================
  --> */}
   <footer className="main-footer mt-4">
    {/* <!--Widgets Section--> */}
    <div className="widgets-section" style={{backgroundImage: "url(./img/background/2.jpg)"}}>
        <div className="container">
            <div className="row">
               <div className="col-md-4">
                <div className="footer-column">
                  <div className="footer-widget about-widget">
                      <div className="logo">
                          <a href="index.html"><img src={logo} alt=""  style={{height:"70px"}}/></a>
                      </div>
                      <div className="text">
                          <p>Join over one million people. We’ve been helping people to invest. Today, our range of investment options and tools make it easier than ever to get started.</p>
                      </div>
                      <ul className="social-icon-two p-0">
                          <li className="title">Follow Us:</li>
                          <li><a href="##"><i className="bi bi-facebook"></i></a></li>
                          <li><a href="##"><i className="bi bi-twitter"></i></a></li>
                          <li><a href="##"><i className="bi bi-skype"></i></a></li>
                          <li><a href="##"><i className="bi bi-linkedin"></i></a></li>
                      </ul>
                  </div>
              </div>
               </div>

               <div className="col-md-2">
                <div className="footer-column">
                  <div className="footer-widget about-widget">
                      <h2 className="widget-title">Links</h2>
                      <ul className="user-links p-0">
                        <li><a href="https://coin-flex.net/index.html">Home</a></li>
                        <li><a href="https://coin-flex.net/about.html">About</a></li>
                        <li><a href="https://coin-flex.net/plan.html">Plans</a></li>
                        <li><a href="https://coin-flex.net/faq.html">Faq</a></li>
                        <li><a href="https://coin-flex.net/login.html">Login</a></li>
                        <li><a href="https://coin-flex.net/register.html">Signup</a></li>
                      </ul>
                  </div>
              </div>
               </div>

               <div className="col-md-3">
                <div className="footer-column">
                  {/* <!--Footer Column--> */}
                  <div className="footer-widget contact-widget">
                      <h2 className="widget-title">Contact Us</h2>
                       {/* <!--Footer Column--> */}
                      <div className="widget-content">
                          <ul className="contact-list p-0">
                              <li className="">
                                  <span className="icon bi bi-globe-americas"></span>
                                  <div className="text">Address<br></br>100 King Street , Ship Canal House and the Reform Club by Edward Salomons.England.</div>
                              </li>

                              <li>
                                  <span className="icon bi bi-envelope-arrow-down"></span>
                                  <div className="text">Email<br></br><a href="mailto:admin@Coinflex.com">admin@Coin-flex.net</a></div>
                              </li>
                          </ul>
                          
                      </div>
                  </div>
              </div>
               </div>

               {/* <div className="col-md-3">
                <div className="footer-column">
                        
                  <div className="footer-widget contact-widget">
                      <h2 className="widget-title">Company's Cert</h2>
                      <div className="upper-column">
                        
                    </div>
                  </div>
              </div>
               </div> */}
            </div>
        </div>
         
    </div>

    {/* <!--Footer Bottom--> */}
    <div className="footer-bottom">

        <div className="container">
            <div className="inner-container clearfix">
                <div className="footer-nav">
                    <ul className="clearfix">
                      <li><a href="contact.html">Contact Us</a></li>
                      <li><a href="rules.html">Terms & Conditions</a></li>

                    </ul>
                </div>

                <div className="copyright-text">
                    <p>2019-2022 © All Rights Reserved by <a href="?a=home">coin-flex.net</a></p>
                </div>
            </div>
        </div>
    </div>
</footer>

    </div>
)
}
export default Register;