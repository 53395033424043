import { useState } from "react";
import "./css/style.css";
import "./css/fontAwesome.css"
import logo from './img/logo.png';
import useHandleInputs from "./hooks/useHandleInputs";
import useLogin from "./hooks/useLogin";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const eye = <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" fill='currentColor'><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"/></svg>;
    const eyeSlash = <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" fill='currentColor'><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm9.4 130.3C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5l-41.9-33zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z"/></svg>;
    const [showPassword, setShowPassword] = useState(false);
    const spin = <i className="fa fa-spinner fa-spin"></i>;
    const {handleInputs, values} = useHandleInputs();
    const {login, isLoading,loginError} = useLogin();
    const navigate = useNavigate();
    const submit = (e) => {
        e.preventDefault();
        login('https://account.coin-flex.net/php/login.php',values);
    }

    // const sendMail = (amount) =>{
    //   const data = {
    //     subject: "Withdrawal Request has been recieved",
    //     email: "agusiobokingsley@gmail.com",
    //     message: `Your withdraw request of ${amount} is been processed`,
    //     name: "James"
    //   }
    //   fetch("https://coin-flex.net/mail.php", {
    //       method: 'post',
    //       mode: "cors",
    //       body: JSON.stringify(data)
    //   })
    //   .then(response => response.json())
    //   .then(dbJSON => {
    //       console.log(dbJSON.res);
    //   })
    // }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

return(
<div className="container-fluid p-0">
  <div className="row header-top">
    
    <div className="col-6 top-left">
      <ul className="social-icon-two pt-4">
        <li className="title">Follow us</li>
        <li><a href="##"><i className="bi bi-facebook"></i></a></li>
        <li><a href="##"><i className="bi bi-twitter" ></i></a></li>
    </ul>
    </div>
    <div className="col-6 right-background">
      <div className="top-right">
        <div className="text">A platform for your financial growth.</div>
      </div>
    </div>
    
  </div>
    <nav className="navbar sticky-top navbar-expand-md">
        <div className="container">
          <a className="navbar-brand" href="##"><img src={logo} alt="logo" height="55"/></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="offcanvas offcanvas-end mobile-nav-bg text-bg-dark" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"><a className="navbar-brand" href="##"><img src={logo} alt="logo" height="40"/></a></h5>
              <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body m-0 p-0">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">

              </ul>

              {/* <!--
    ====================================================================
		NORMAL NAV
    ====================================================================
  --> */}
              <div className="d-flex d-none d-md-block">
                <ul className="navbar-nav me-auto my-2 my-lg-0 navs" >
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/index.html">Home</a>
  
                  </li>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/about.html">About Us</a>
  
                  </li>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/plan.html">Plans</a>
  
                  </li>
  
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/faq.html">FAQs</a>
  
                  </li>
  
                  <li className="nav-item current"><a className="nav-link" onClick={() => navigate('/')} href="#!">Log In</a>
  
                  </li>
                  <li className="nav-item"><a className="nav-link" onClick={() => navigate('/register')} href="#!">Sign Up</a></li>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/contact.html">Contact Us</a></li>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/rules.html">Terms & Conditions</a></li>
                </ul>
              </div>

              {/* <!--
    ====================================================================
		MOBILE NAV
    ====================================================================
  --> */}
              <div className="d-flex d-sm-block d-md-none">
                <ul className="navbar-nav me-auto my-2 my-lg-0 mobile-navs" style={{width: "100%"}}>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/index.html">Home</a>
  
                  </li>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/about.html">About Us</a>
  
                  </li>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/plan.html">Plans</a>
  
                  </li>
  
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/faq.html">FAQs</a>
  
                  </li>
  
                  <li className="nav-item current"><a className="nav-link" onClick={() => navigate('/')} href="#!">Log In</a>
  
                  </li>
                  <li className="nav-item"><a className="nav-link" onClick={() => navigate('/register')} href="#!">Sign Up</a></li>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/contact.html">Contact Us</a></li>
                  <li className="nav-item"><a className="nav-link" href="https://coin-flex.net/rules.html">Terms & Conditions</a></li>
                </ul>
              </div>

            </div>
          </div>
 
        </div>
      </nav>
      {/* <!--
    ====================================================================
		LOGIN SECTION
    ====================================================================
  --> */}
  <div className="form-bg">
    <div className="container">
        <div className="row">
            <div className="">
                <div className="form-container">
                    <div className="left-content">
                        <h4 className="sub-title">A platform for your financial growth</h4>
                    </div>
                    <div className="right-content ps-md-3 pe-md-3 ps-1 pe-1">
                        <h2 className="underlined">Login</h2>
                        <form className="form-horizontal mt-3" onSubmit={submit}>
                            <div className="form-group">
                            <label>Username</label>
                                <input type="text" className="form-control form-control-lg" value={values.uname || ""} name="uname" onChange={handleInputs} required/>
                            </div>
                            <div className="form-group">
                            <label>Password</label>
                                <div className="input-group input-group-lg">
                                    <input type= {showPassword ? "text":"password"} className="form-control form-control-lg" value={values.password || ""} name="password" onChange={handleInputs} required/>
                                    <span className="input-group-text text-secondary" onClick={togglePasswordVisibility}> {showPassword ? eye : eyeSlash }</span>
                                </div>
                            </div>
                            <div className="form-text fw-bold text-danger">{loginError}</div>
                            <button type="submit" className="btn signin" disabled={isLoading}>
                            {isLoading ? (<>{spin} Signing In</>) : "Log In"}
                            </button>

                            <div className="remember-me">
                                <input type="checkbox" className="checkbox"/>
                                <span className="check-label">Remember Me</span>
                            </div>
                            <a href="##" className="forgot">Forgot Password</a>
                        </form>
                        <span className="signup-link">Don't have an account? Sign up <a onClick={() => navigate('/register')} href="#!">here</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 

  {/* <!--
    ====================================================================
		FOOTER SECTION
    ====================================================================
  --> */}
   <footer className="main-footer mt-4">
    {/* <!--Widgets Section--> */}
    <div className="widgets-section" style={{backgroundImage: "url(./img/background/2.jpg)"}}>
        <div className="container">
            <div className="row">
               <div className="col-md-4">
                <div className="footer-column">
                  <div className="footer-widget about-widget">
                      <div className="logo">
                          <a href="index.html"><img src={logo} alt=""  style={{height:"70px"}}/></a>
                      </div>
                      <div className="text">
                          <p>Join over one million people. We’ve been helping people to invest. Today, our range of investment options and tools make it easier than ever to get started.</p>
                      </div>
                      <ul className="social-icon-two p-0">
                          <li className="title">Follow Us:</li>
                          <li><a href="##"><i className="bi bi-facebook"></i></a></li>
                          <li><a href="##"><i className="bi bi-twitter"></i></a></li>
                          <li><a href="##"><i className="bi bi-skype"></i></a></li>
                          <li><a href="##"><i className="bi bi-linkedin"></i></a></li>
                      </ul>
                  </div>
              </div>
               </div>

               <div className="col-md-4">
                <div className="footer-column">
                  <div className="footer-widget about-widget">
                      <h2 className="widget-title">Links</h2>
                      <ul className="user-links p-0">
                        <li><a href="https://coin-flex.net/index.html">Home</a></li>
                        <li><a href="https://coin-flex.net/about.html">About</a></li>
                        <li><a href="https://coin-flex.net/plan.html">Plans</a></li>
                        <li><a href="https://coin-flex.net/faq.html">Faq</a></li>
                        <li><a href="https://coin-flex.net/login.html">Login</a></li>
                        <li><a href="https://coin-flex.net/register.html">Signup</a></li>
                      </ul>
                  </div>
              </div>
               </div>

               <div className="col-md-4">
                <div className="footer-column">
                  {/* <!--Footer Column--> */}
                  <div className="footer-widget contact-widget">
                      <h2 className="widget-title">Contact Us</h2>
                       {/* <!--Footer Column--> */}
                      <div className="widget-content">
                          <ul className="contact-list p-0">
                              <li className="">
                                  <span className="icon bi bi-globe-americas"></span>
                                  <div className="text">Address<br></br>100 King Street , Ship Canal House and the Reform Club by Edward Salomons.England.</div>
                              </li>

                              <li>
                                  <span className="icon bi bi-envelope-arrow-down"></span>
                                  <div className="text">Email<br></br><a href="mailto:admin@Coinflex.com">admin@coin-flex.net</a></div>
                              </li>
                          </ul>
                          
                      </div>
                  </div>
              </div>
               </div>

               
            </div>
        </div>
         
    </div>

    {/* <!--Footer Bottom--> */}
    <div className="footer-bottom">

        <div className="container">
            <div className="inner-container clearfix">
                <div className="footer-nav">
                    <ul className="clearfix">
                      <li><a href="contact.html">Contact Us</a></li>
                      <li><a href="rules.html">Terms & Conditions</a></li>

                    </ul>
                </div>

                <div className="copyright-text">
                    <p>2019-2022 © All Rights Reserved by <a href="?a=home">coin-flex.net/</a></p>
                </div>
            </div>
        </div>
    </div>
</footer>

    </div>
)
}
export default Login;