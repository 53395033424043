import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import useFetchData from "./hooks/useFetchData";
import Loader from "./components/Loader";
import useTransactionHistory from "./hooks/useTransactionHistory";
import { useEffect } from "react";

export default function History(){
    const {data,loading} = useFetchData();
    const {transationsHistory, transationList} = useTransactionHistory();

    useEffect(() => {
        if (data.id) {
            transationsHistory(data.id);
        }
    }, [data.id, transationsHistory]);

    return(
        <div className="d-flex" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
            <Navbar />
            <main className="min-vh-100 ms-2 me-2 margin-right ms-md-5 me-md-5">
            {loading ? (
            <Loader />
            ) : (
                <div className="animate__animated animate__fadeIn">
                    <div className="col-md-12">
                    <div className="card box2 pe-1 ps-1 p-0" >
                    <div className="card-body row pe-0">
                    <div className="breadcrumb-item h4 bg-transparent mt-1" aria-current="page">Transaction History</div>
                    </div>
                    </div>
                    </div>

                    <div className="row gy-4 mt-1">
                    <div className="col-md-12">
                    <div className="card box pe-1 ps-1 p-0" >
                    <div className="card-body row pe-0">
                    <div className="col-5 info-box bg-warning-subtle">
                    <i className="bi bi-person-vcard"></i>
                    </div>
                    <div className="col-7 ps-1 p-0">
                    <h4 className="">{data.firstname} {data.lastname}</h4>
                    <span>Registration Date: {data.reg_date}</span>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>


                    <div className="col-12 mt-4">
                    <div className="card box3 " >
                    <div className="card-body">
                    {transationList}
                    </div>
                    </div>
                    </div>
                </div>
            )}
            </main>
            </div>
        </div>
        
    )
}