import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import useFetchData from "./hooks/useFetchData";
import Loader from "./components/Loader";
import useHandleInputs from "./hooks/useHandleInputs";
import useProcessTransactions from "./hooks/useProcessTransactions";

export default function Withdraw(){
    const {data,loading} = useFetchData();
    const {handleInputs, values} = useHandleInputs();
    const {isLoading, withdraw} = useProcessTransactions();
    const spin = <i className="fa fa-spinner fa-spin"></i>;

    const submit = (e) =>{
        e.preventDefault();
        values.userID = data.id;
        values.type = "withdrawal";
        values.plan = data.plan;
        values.balance = parseInt(data.balance, 10) - parseInt(values.amount, 10);
        let amount = values.amount;
        const mailData = {
            name: data.firstname,
            email: data.email,
            message: `Your Withdrawal request of $${amount} is been processed and will be confirmed within the next 24hrs`,
            subject: "Withdrawal Request has been recieved",
        }
        withdraw(values,mailData)
    }

    return(
        <div className="d-flex" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
            <Navbar />
            <main className="min-vh-100 ms-2 me-2 margin-right ms-md-5 me-md-5">
            {loading ? (
            <Loader />
            ) : (
                <div className="animate__animated animate__fadeIn">
                    <div className="col-md-12">
                    <div className="card box2 pe-1 ps-1 p-0" >
                    <div className="card-body row pe-0">
                    <div className="breadcrumb-item h4 bg-transparent mt-1" aria-current="page">Withdraw Funds</div>
                    </div>
                    </div>
                    </div>

                    <div className="row gy-4 mt-1">
                    <div className="col-md-12">
                    <div className="card box pe-1 ps-1 p-0" >
                    <div className="card-body row pe-0">
                    <div className="col-5 info-box bg-warning-subtle">
                    <i className="bi bi-person-vcard"></i>
                    </div>
                    <div className="col-7 ps-1 p-0">
                    <h4 className="">{data.firstname} {data.lastname}</h4>
                    <span>Balance: ${data.balance}</span>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>

                    <div className="col-12 mt-4">
                    <div className="card box3 " >
                    <div className="card-body">
                    <form onSubmit={submit}>
                    <div className="mb-3">
                    <label className="form-label">Select Wallet</label>
                    <select id="accounts" value={values.wallet} name="wallet" onChange={handleInputs} className="form-control form-select-lg" aria-label=".form-select-lg" required>
                    <option>Select wallet</option>
                    <option>Bitcoin wallet</option>
                    <option>USDT TRC20 wallet</option>
                    <option>TRON trx</option>
                    <option>USDT Erc20</option>
                    <option>USDT bnb20</option>
                    </select>
                    </div>

                    <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Enter Address</label>
                    <input type="text" value={values.address} name="address" onChange={handleInputs} className="form-control form-control-lg"  required/>
                    </div>

                    <div className="mb-5">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Enter Amount</label>
                    <input type="number" value={values.amount || ""} name="amount" onChange={handleInputs} className="form-control form-control-lg" id="exampleFormControlInput1" max={data.balance} required/>
                    </div>

                    <div className="d-grid gap-2">
                    <button className="btn btn-danger btn-lg" type="submit">
                    {isLoading ? (<>{spin} Confirming</>) : "Withdraw Fund"}
                    </button>
                    </div>
                    </form>
                    </div>
                    </div>
                    </div>
                </div>
            )}
            </main>
            </div>
        </div>
        
    )
}