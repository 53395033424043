import {NavLink, useNavigate } from "react-router-dom";
import logo from './../img/logo.png';
import useMessage from "../hooks/useMessage";
import { useEffect } from "react";


const Sidebar = (props) => {
  const navigate = useNavigate();
  const {loadMessage, messageUnread} = useMessage();
  const logOut =()=> {
    sessionStorage.clear();
    fetch('https://astr.fastpropfunding.com/php2/logout.php');
    navigate("/");
  }
  useEffect(()=>{
    loadMessage();
  },[])
  
return (
    
<aside className="" id="sidebar-wrapper">
    <div className="sidebar-heading mt-md-1"><img src={logo} alt="logo" height="56" className="d-inline-block" /></div>

    <div className='contents'>
    <div className="list-group list-group-flush mt-4 rounded-top">

    <NavLink
    to="/Account"
    className={({ isActive, isPending }) =>
    isPending ? "list-group-item list-group-item-action p-1 pe-2 ps-2 sidebar_link " : isActive ? "active p-1 pe-2 ps-2 sidebar_link" : "p-1 pe-2 ps-2 sidebar_link"
    }
    >
  	<span className="sidebar_icon">
  	<i className="bi bi-person-vcard"></i>
  	</span>
    <span className="sidebar_text">Account Overview</span>
  	</NavLink>

    <NavLink 
    to="/Investments"
    className={({ isActive, isPending }) =>
    isPending ? "list-group-item list-group-item-action p-1 pe-2 ps-2 sidebar_link" : isActive ? "active p-1 pe-2 ps-2 sidebar_link" : "p-1 pe-2 ps-2 sidebar_link"
    }
    >
  	<span className="sidebar_icon">
    <i className="bi bi-handbag"></i>
    </span>
    <span className="sidebar_text">Your Investments</span>
  	</NavLink>
    
    <NavLink 
    to="/History"
    className={({ isActive, isPending }) =>
    isPending ? "list-group-item list-group-item-action p-1 pe-2 ps-2 sidebar_link" : isActive ? "active p-1 pe-2 ps-2 sidebar_link" : " p-1 pe-2 ps-2 sidebar_link"
    }
    >
  	<span className="sidebar_icon">
    <i className="bi bi-person"></i>
    </span>
    <span className="sidebar_text">Transaction History</span>
  	</NavLink>

    <NavLink 
    to="/Deposit"
    className={({ isActive, isPending }) =>
    isPending ? "list-group-item list-group-item-action p-1 pe-2 ps-2 sidebar_link" : isActive ? "active p-1 pe-2 ps-2 sidebar_link" : " p-1 pe-2 ps-2 sidebar_link"
    }
    >
  	<span className="sidebar_icon">
    <i className="bi bi-wallet2"></i>
    </span>
    <span className="sidebar_text">Make Deposit</span>
  	</NavLink>

    <NavLink 
    to="/Withdraw"
    className={({ isActive, isPending }) =>
    isPending ? "list-group-item list-group-item-action p-1 pe-2 ps-2 sidebar_link" : isActive ? "active p-1 pe-2 ps-2 sidebar_link" : " p-1 pe-2 ps-2 sidebar_link"
    }
    >
  	<span className="sidebar_icon">
    <i className="bi bi-cash-stack"></i>
    </span>
    <span className="sidebar_text">Withdraw Funds</span>
  	</NavLink>

    <NavLink 
    to="/inbox"
    className={({ isActive, isPending }) =>
    isPending ? "list-group-item list-group-item-action p-1 pe-2 ps-2 sidebar_link" : isActive ? "active p-1 pe-2 ps-2 sidebar_link" : " p-1 pe-2 ps-2 sidebar_link"
    }
    >
  	<span className="sidebar_icon">
    <i className="bi bi-chat-left-dots"></i>
    </span>
    <span className="sidebar_text position-relative">
    <span className="me-3" style={{height:311}}>Inbox</span>
    {messageUnread > 0 ? 
    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
    {messageUnread}
    <span className="visually-hidden">New Message</span>
    </span>
    : <></>}
    </span>
  	</NavLink>

    <nav className="mt-5 p-1 pe-2 ps-2 sidebar_link" onClick={logOut}>
    <div className="col-4">
    <span className="sidebar_icon">
    <i className="bi bi-box-arrow-left"></i>
    </span>
    </div>
  	<div className="col-8"><span className="sidebar_text">Logout</span></div>
    </nav>
    


    

    

    </div>
    </div>
</aside>
);
}
export default Sidebar;