import Loader from "../Loader";
import useAccounts from "../../hooks/useAccounts";

const AccountModal = (props) =>{
    const {displayAccounts, isLoading, accounts} = useAccounts();

  return(
        <div id="myModal" className="modal2 animate__animated animate__fadeIn" style={{ display: props.display? "block":"none" }}>
        <div className="modal-content2 panel rounded-4">

        <div className="panel-footer row">
        <div className="col-6 btn_group text-start">
        <button className="btn btn-default me-1" onClick={displayAccounts}><i className={isLoading?"fa fa-spinner fa-spin": "fa fa-sync-alt"}></i></button>
        </div>

        <div className=" col-6 btn_group text-end">
        <button type="button" className="btn border-0 btn-default" onClick={props.close}><i className="fa-solid fa-x"></i></button>
        </div>
        
        </div>

        <div className="modal-body  text-light">
        { isLoading? ( <Loader color='border-light' />
        ) :(
            accounts
        )}
        </div>
        </div>
        </div>
  )   
}
export default AccountModal;