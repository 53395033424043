import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

const useProcessTransactions = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [loading2, setLoading] = useState(false);
    const navigate = useNavigate();
    

    const deposit = (data,mailData) => {

        setIsLoading(true);

         fetch("https://account.coin-flex.net/php/transaction.php",{
         method: 'post',
         mode: "cors",
         body: JSON.stringify(data)
         })
         .then(response => response.json())
         .then(dbJSON => {
         if(dbJSON === "successful"){
            fetch("https://coin-flex.net/mail.php", {
                method: 'post',
                mode: "cors",
                body: JSON.stringify(mailData)
            })
            .then(response => response.json())
            .then(dbJSON => {
                if(dbJSON.res==="successful"){
                    setIsLoading(false);
                    Swal.fire({
                        text: "Transaction Successful! Awaiting Confirmation",
                        iconColor: "#0883FF",
                        confirmButtonColor: "#0883FF",
                    }).then((result) => {
                        if (result.isConfirmed){
                            navigate(0);
                        }
                    })
                }
            })
         }
     })
    }

    const withdraw = (data,mailData) => {
        setIsLoading(true);

         fetch("https://account.coin-flex.net/php/withdraw.php",{
         method: 'post',
         mode: "cors",
         body: JSON.stringify(data)
         })
         .then(response => response.json())
         .then(dbJSON => {
         if(dbJSON === "successful"){
            fetch("https://coin-flex.net/mail.php", {
                method: 'post',
                mode: "cors",
                body: JSON.stringify(mailData)
            })
            .then(response => response.json())
            .then(dbJSON => {
                if(dbJSON.res==="successful"){
                    setIsLoading(false);
                    Swal.fire({
                        text: "Transaction Successful! Awaiting Confirmation",
                        iconColor: "#0883FF",
                        confirmButtonColor: "#0883FF",
                    }).then((result) => {
                        if (result.isConfirmed){
                            navigate(0);
                        }
                    })
                }
            })


         }
     })
    }

    const confirm = (data,load,mailData) => {
            
        setIsLoading(true);

         fetch("https://account.coin-flex.net/php/confirm.php",{
         method: 'post',
         mode: "cors",
         body: JSON.stringify(data)
         })
         .then(response => response.json())
         .then(dbJSON => {
         if(dbJSON === "successful"){
            fetch("https://coin-flex.net/mail.php", {
                method: 'post',
                mode: "cors",
                body: JSON.stringify(mailData)
            })
            .then(response => response.json())
            .then(dbJSON =>{
                if(dbJSON.res==="successful"){
                    setIsLoading(false);
                    Swal.fire({
                        text: "Transaction Confirmed",
                        iconColor: "#0883FF",
                        confirmButtonColor: "#0883FF",
                    }).then((result) => {
                        if (result.isConfirmed){
                            load();
                        }
                    })
                }
            })
            }
        })
    }

    const confirmWithdraw = (data,load,mailData) => {
            
        setIsLoading(true);

         fetch("https://account.coin-flex.net/php/confirmWithdraw.php",{
         method: 'post',
         mode: "cors",
         body: JSON.stringify(data)
         })
         .then(response => response.json())
         .then(dbJSON => {
         if(dbJSON === "successful"){
            fetch("https://coin-flex.net/mail.php", {
                method: 'post',
                mode: "cors",
                body: JSON.stringify(mailData)
            })
            .then(response => response.json())
            .then(dbJSON =>{
                if(dbJSON.res==="successful"){
                    setIsLoading(false);
                    Swal.fire({
                        text: "Transaction Confirmed",
                        iconColor: "#0883FF",
                        confirmButtonColor: "#0883FF",
                    }).then((result) => {
                        if (result.isConfirmed){
                            load();
                        }
                    })
                }
            })
            }
        })
    }

    const cancelWithdraw = (data,load) => {         
        setIsLoading(true);
         fetch("https://account.coin-flex.net/php/cancelWithdraw.php",{
         method: 'post',
         mode: "cors",
         body: JSON.stringify(data)
         })
         .then(response => response.json())
         .then(dbJSON => {
         if(dbJSON === "successful"){
                setIsLoading(false);
                Swal.fire({
                    text: "Withdrawal Cancelled",
                    iconColor: "#0883FF",
                    confirmButtonColor: "#0883FF",
                }).then((result) => {
                    if (result.isConfirmed){
                        load();
                    }
                })
            }
        })
    }

    const cancelDeposit = (data,load) => {         
        setIsLoading(true);
         fetch("https://account.coin-flex.net/php/cancelDeposit.php",{
         method: 'post',
         mode: "cors",
         body: JSON.stringify(data)
         })
         .then(response => response.json())
         .then(dbJSON => {
         if(dbJSON === "successful"){
                setIsLoading(false);
                Swal.fire({
                    text: "Deposit Canceled",
                    iconColor: "#0883FF",
                    confirmButtonColor: "#0883FF",
                }).then((result) => {
                    if (result.isConfirmed){
                        load();
                    }
                })
            }
        })
    }

    const withdrawInvest = (data) => {
        setLoading(true);

         fetch("https://account.coin-flex.net/php/withdrawInvest.php",{
         method: 'post',
         mode: "cors",
         body: JSON.stringify(data)
         })
         .then(response => response.json())
         .then(dbJSON => {
         if(dbJSON === "successful"){
            setLoading(false);
             Swal.fire({
                 text: "Withdrawal Successful",
                 iconColor: "#0883FF",
                 confirmButtonColor: "#0883FF",
             }).then((result) => {
                 if (result.isConfirmed){
                     navigate(0);
                 }
             })
         }
     })
    }

return {
    deposit,
    isLoading,
    withdraw,
    confirm,
    withdrawInvest,
    loading2,
    confirmWithdraw,
    cancelWithdraw,
    cancelDeposit
}
}
export default useProcessTransactions